@import url("../Constants/Colors.css");

.tutorialCardContainer {
  width: 320px;
  height: 450px;
  background-color: var(--black);
  border-radius: 30px;
  box-shadow: 0 0 25px 5px rgba(165, 46, 46, 0.308);
  border: 3px solid var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorialCardInner {
  width: 250px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.cardImage {
  max-height: 170px;
  max-width: 280px;
  object-fit: cover; /* Resmi konteyner içine sığdırırken oranları korur */
}

.cardTitle {
  font-size: 22px;
  color: var(--white);
  font-weight: 600;
  text-align: center;
}

.cardButton {
  width: 160px;
  height: 48px;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  border-radius: 100px;
  border: 2px solid var(--primary-color);
  transition: all 300ms;
  cursor: pointer;
}

.cardButton:hover {
  background-color: transparent;
}

.cardImageArea {
  max-width: 280px;
  min-height: 170px;
  max-height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1268px) {
  .tutorialCardContainer {
    width: 270px;
    height: 400px;
  }
  .tutorialCardInner {
    width: 230px;
    height: 320px;
  }
  .cardImageArea {
    max-width: 230px;
    min-height: 150px;
    max-height: 150px;
  }

  .cardImage {
    max-height: 150px;
    max-width: 230px;
    object-fit: cover; /* Resmi konteyner içine sığdırırken oranları korur */
  }

  .cardButton {
    width: 130px;
    height: 42px;
    font-size: 15px;
    font-weight: 500;
  }
}

@media (max-width: 568px) {
  .tutorialCardContainer {
    width: 270px;
    height: 400px;
  }
  .tutorialCardInner {
    width: 200px;
    height: 320px;
  }
  .cardImageArea {
    max-width: 170px;
    min-height: 140px;
    max-height: 140px;
  }
  .cardImage {
    max-height: 140px;
    max-width: 220px;
    object-fit: cover; /* Resmi konteyner içine sığdırırken oranları korur */
  }
  .cardButton {
    width: 130px;
    height: 42px;
    font-size: 15px;
    font-weight: 500;
  }
  .cardTitle {
    font-size: 18px;
  }
}
