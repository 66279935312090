@import url("../../../Constants/Colors.css");

.tutorialsContainer {
  width: 100%;
  height: auto; /* Ekranın tamamını kaplamasını sağlar */
  background-color: var(--black);
  padding-top: 140px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorialsInner {
  width: 100%;
  max-width: 1300px;
  padding-bottom: 30px;
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr; /* Başlık, kartlar ve boşluk için sırasıyla */
  gap: 20px; /* Boşluklar arası mesafe */
  justify-items: center; /* İçeriği yatayda ortala */
  align-items: center; /* İçeriği dikeyde ortala */
}

.tutorialsHeaderArea {
  grid-column: 1 / -1; /* Tüm sütunları kapla */
  height: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.tutorialsHeader {
  font-size: 50px;
  font-weight: 700;
  color: var(--primary-color);
}

.tutorialsCardArea {
  grid-column: 1 / -1; /* Tüm sütunları kapla */
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Üç eşit genişlikte sütunlar */
  gap: 100px; /* Boşluklar arası mesafe */
  justify-items: center; /* İçeriği yatayda ortala */
  align-items: flex-start; /* İçeriği dikeyde baştan hizala */
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (max-width: 1268px) {
  .tutorialsCardArea {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Ekran genişliği 1268px altında 2 sütuna düşer */
    gap: 70px; /* Sütunlar arası boşluk azaltıldı */
  }

  .tutorialsHeader {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .tutorialsCardArea {
    grid-template-columns: 1fr;
    gap: 50px;
  }

  .tutorialsHeader {
    font-size: 30px;
  }
}
@media (max-width: 568px) {
  .tutorialsHeader {
    font-size: 24px;
  }
  .tutorialsHeaderArea {
    display: flex;
  }
  .tutorialsContainer {
    padding-top: 155px;
  }
}
@media (max-width: 568px) {
  .tutorialsHeader {
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    width: 90%;
  }
}

.tutorialsNotFound {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 50px;
  padding-top: 150px;
  padding-bottom: 150px;
}
