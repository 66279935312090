@import url('../../../Constants/Colors.css');

.landingPageVideoContainer * {
  font-family: 'Helvatica', sans-serif;
}

.landingPageVideoContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingPageVideoInner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPageVideoTitle {
  color: var(--white);
  font-weight: 700;
  font-size: 50px;
  text-align: center;
  margin-bottom: 70px;
}

.landingPageVideoTitleColored {
  color: var(--primary-color);
}

.landingPageVideoColumn {
  display: flex;
  flex-direction: column;
}

.landingPageVideoPlayer {
  width: 226px;
  height: 400px;
  margin-bottom: 30px;
}
.landingPageVideoArea {
  display: flex;
  width: 75%;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1338px) {
  .landingPageVideoPlayer {
    width: 180px;
    height: 320px;
  }
  .landingPageVideoArea {
    width: 75%;
  }
}

@media (max-width: 1098px) {
  .landingPageVideoPlayer {
    width: 150px;
    height: 266px;
  }
  .landingPageVideoArea {
    width: 75%;
  }
  .landingPageVideoTitle {
    font-size: 42px;
  }
}
@media (max-width: 968px) {
  .landingPageVideoPlayer {
    width: 226px;
    height: 400px;
    margin-bottom: 30px;
  }
  .landingPageVideoColumn {
    max-width: 250px;
  }
  .landingPageVideoArea {
    max-width: 550px;
  }
  .landingPageVideoArea {
    justify-content: space-between;
  }
}

@media (max-width: 798px) {
  .landingPageVideoTitle {
    font-size: 38px;
  }
}

@media (max-width: 638px) {
  .landingPageVideoArea {
    max-width: 226px;
  }
  .landingPageVideoColumn {
    max-width: 226px;
  }
  .landingPageVideoTitle {
    font-size: 32px;
  }
}

@media (max-width: 538px) {
  .landingPageVideoTitle {
    font-size: 30px;
  }
}

@media (max-width: 398px) {
  .landingPageVideoTitle {
    font-size: 28px;
  }
}
