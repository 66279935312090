.popup-loader-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Düşük opaklık */
  z-index: 1000;
}

.popup-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: rgb(255, 255, 255); /* Beyaz, ancak yarı opak */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-spinner {
  border: 4px solid rgb(18, 1, 1);
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
