@media (max-width: 1238px) {
  :root {
    --login-background-url: url("/public/assets/Backgrounds/Login_Background.webp");
  }

  .loginContent {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.55) 1%,
        rgba(0, 0, 0, 0.97) 100%
      ),
      var(--login-background-url) !important;
    background-size: cover; /* Arka planın tamamen kapsamasını sağlar */
    background-position: center; /* Arka planın merkezlenmesini sağlar */
    background-repeat: no-repeat; /* Arka planın tekrar etmesini engeller */
  }
  .loginBox {
    width: 800px;
    height: 530px;
  }

  .loginLeft {
    width: 400px;
    height: 530px;
  }

  .loginRight {
    width: 400px;
    height: 530px;
  }

  .loginHeaderArea {
    width: 100%;
    height: 75px;
  }
  .loginForm {
    width: 350px;
    height: 440px;
  }
  .loginHeader {
    font-size: 42px;
  }
  .loginSubtitle {
    font-size: 16px;
  }
  .loginFormArea {
    width: 320px;
    height: 315px;
  }

  .loginUsernameArea {
    height: 75px;
  }

  .loginUsername {
    font-size: 14px;
  }
  .loginPassArea {
    width: 300px;
    height: 95px;
    margin-top: 5px;
  }

  .loginButtonArea {
    width: 100%;
    height: 80px;
  }

  .loginRegister {
    width: 100%;
    height: 40px;
  }

  .loginUsernameInput {
    max-width: 270px;
    height: 35px;
    font-size: 14px;
  }

  .loginPassInput {
    max-width: 270px;
    height: 35px;
    font-size: 14px;
  }

  .loginPass {
    font-size: 14px;
  }

  .loginForgotPass {
    font-size: 13px;
    color: var(--gray);
    transition: all 200ms;
    margin-bottom: 5px;
  }

  .loginButton {
    width: 300px;
    height: 40px;
    margin-top: 15px;
    font-size: 14px;
  }
  .loginSub {
    font-size: 15px;
  }
  .loginRegisterText {
    font-size: 15px;
  }
  .loginQuest {
    font-size: 13px;
  }
  .loginRightText {
    font-size: 16px;
    margin-top: 30px;
  }

  .loginLogo {
    object-fit: cover;
    height: 530px;
    border-radius: 30px;
  }
}

@media (max-width: 1038px) {
  .loginRight {
    display: none;
  }
  .loginBox {
    width: 400px;
    height: 530px;
  }

  .loginLeft {
    width: 400px;
    height: 530px;
  }
  .loginForm {
    width: 400px;
    align-items: center;
  }
  .loginHeaderArea {
    width: 300px;
  }
  .loginFormArea {
    width: 300px;
  }
}

@media (max-width: 738px) {
  .loginRight {
    display: none;
  }
  .loginBox {
    width: 300px;
    height: 480px;
    border-radius: 10px;
  }

  .loginLeft {
    width: 300px;
    height: 480px;
  }
  .loginForm {
    width: 300px;
    height: 400px;
    align-items: center;
  }
  .loginHeaderArea {
    width: 250px;
    height: 65px;
  }
  .loginFormArea {
    width: 250px;
    height: 300px;
  }
  .loginPassArea {
    width: 250px;
  }
  .loginHeader {
    font-size: 36px;
  }
  .loginSubtitle {
    font-size: 14px;
  }
  .loginSub {
    font-size: 14px;
  }
  .loginRegisterText {
    font-size: 14px;
  }
  .loginQuest {
    font-size: 12px;
  }
  .loginUsernameInput {
    height: 33px;
    font-size: 13px;
  }

  .loginPassInput {
    height: 33px;
    font-size: 13px;
  }
}
