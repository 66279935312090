@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("./Constants/Colors.css");

::selection {
  background-color: var(--primary-color);
}

/* Kırmızı metinler için özel seçim stili */
.red-selection::selection {
  background: var(--white);
  color: var(--primary-color); /* Seçim arka planı beyaz, metin rengi kırmızı */
}

/* Scrollbar için genel stiller */
::-webkit-scrollbar {
  width: 13px; /* Scrollbar genişliği */
}

/* Scrollbar track (arka plan) stilleri */
::-webkit-scrollbar-track {
  background: var(--black); /* Arka plan rengi */
}

::-webkit-scrollbar-corner {
  background: var(--black);
}

/* Scrollbar thumb (kaydırma çubuğu) stilleri */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Kaydırma çubuğu rengi */
  border-radius: 10px; /* Kenar yuvarlatma */
  border: 3px solid var(--black); /* İç kenar boşluğu */
}

/* Scrollbar thumb hover (üzerine gelince) stilleri */
::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Hover rengi */
}

a {
  text-decoration: none;
}

/* * {
  transition: all 200ms linear;
} */

html,
body {
  scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Montserrat", sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
