@import url("../../../Constants/Colors.css");

:root {
  --login-background-url: url("/public/assets/Backgrounds/Login_Background.webp");
}

.landingPageWhatHaveContainer * {
  font-family: "Helvatica", sans-serif;
}

.landingPageWhatHaveContainer {
  width: 100%;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 1%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    var(--login-background-url) !important;
  background-size: cover; /* Arka planın tamamen kapsamasını sağlar */
  background-position: center; /* Arka planın merkezlenmesini sağlar */
  background-repeat: no-repeat; /* Arka planın tekrar etmesini engeller */
}

.landingPageWhatHaveInner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 60px;
}

.landingPageWhatHaveTitle {
  font-size: 50px;
  color: var(--white);
  font-weight: 800;
  margin-top: 80px;
  text-align: center;
}

.landingPageWhatHaveTitleColored {
  color: var(--primary-color);
}
.landingPageWhatHaveItem.first {
  margin-top: 70px;
}
.landingPageWhatHaveItem {
  width: 65%;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.landingPageWhatHaveItemText {
  width: 350px;
  display: flex;
  flex-direction: column;
}

.landingPageWhatHaveItemTitle {
  font-weight: 600;
  font-size: 28px;
  color: var(--white);
}

.landingPageWhatHaveItemDescription {
  font-weight: 400;
  font-size: 18px;
  color: var(--light-gray);
  letter-spacing: 0.8px;
  line-height: 24px;
  margin-top: 20px;
}

.landingPageWhatHavePhoto {
  max-width: 350px;
  max-height: 300px;
  object-fit: cover;
}

.landingPageWhatHavePhotoArea {
  width: 350px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.landingPageWhatHavePhotoArea.second {
  width: 350px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.landingPageWhatHaveLine {
  position: absolute;
  bottom: 225px;
}

.landingPageWhatHaveLinePhoto {
  height: 950px;
  object-fit: cover;
  width: 8px;
}

.landingPageProgramTitleInner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  font-family: "Montserrat", sans-serif;
}

.landingPageProgramTitle {
  font-size: 56px;
  color: var(--white);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.landingPageProgramTitleColored {
  color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
}

.landingPageProgramSubtitle {
  font-size: 20px;
  color: var(--light-gray);
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
}

.landingPageProgramSubtitleColored {
  color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1138px) {
  .landingPageProgramTitleContainer {
    height: 230px;
  }

  .landingPageProgramTitle {
    font-size: 48px;
  }

  .landingPageProgramSubtitle {
    font-size: 18px;
    margin-top: 30px;
  }
}

@media (max-width: 938px) {
  .landingPageProgramTitleContainer {
    height: 200px;
  }

  .landingPageProgramTitle {
    font-size: 40px;
  }

  .landingPageProgramSubtitle {
    font-size: 16px;
    margin-top: 25px;
  }
}

@media (max-width: 738px) {
  .landingPageProgramTitleContainer {
    height: 200px;
  }

  .landingPageProgramTitle {
    font-size: 36px;
  }

  .landingPageProgramSubtitle {
    font-size: 14px;
    margin-top: 25px;
  }
}

@media (max-width: 638px) {
  .landingPageProgramTitleContainer {
    height: 200px;
  }

  .landingPageProgramTitle {
    font-size: 32px;
  }

  .landingPageProgramSubtitle {
    font-size: 14px;
    margin-top: 25px;
    width: 400px;
    text-align: center;
  }
}

@media (max-width: 498px) {
  .landingPageProgramTitleContainer {
    height: 180px;
  }

  .landingPageProgramTitle {
    font-size: 28px;
  }

  .landingPageProgramSubtitle {
    font-size: 12px;
    margin-top: 20px;
    width: 350px;
    text-align: center;
  }
}

@media (max-width: 438px) {
  .landingPageProgramTitleContainer {
    height: 150px;
  }

  .landingPageProgramTitle {
    font-size: 22px;
    text-align: center;
  }

  .landingPageProgramSubtitle {
    font-size: 12px;
    margin-top: 16px;
    width: 300px;
    text-align: center;
  }
}

@media (max-width: 1198px) {
  .landingPageWhatHaveItem {
    width: 80%;
  }
  .landingPageWhatHaveTitle {
    font-size: 46px;
  }
}
@media (max-width: 968px) {
  .landingPageWhatHaveItem {
    width: 90%;
    height: 250px;
  }
  .landingPageWhatHaveTitle {
    font-size: 38px;
  }

  .landingPageWhatHaveItemTitle {
    font-size: 20px;
  }
  .landingPageWhatHaveItemDescription {
    font-size: 14px;
  }

  .landingPageWhatHaveItemText {
    width: 300px;
  }

  .landingPageWhatHavePhoto {
    max-width: 300px;
    max-height: 250px;
  }

  .landingPageWhatHavePhotoArea {
    width: 300px;
    height: 250px;
  }

  .landingPageWhatHavePhotoArea.second {
    width: 300px;
    height: 250px;
  }

  .landingPageWhatHaveLinePhoto {
    height: 850px;
    width: 7px;
  }
}

@media (max-width: 768px) {
  .landingPageWhatHaveItem {
    width: 90%;
    height: auto;
    flex-direction: column;
    justify-content: center;
  }

  .landingPageWhatHaveItem.second {
    width: 90%;
    height: auto;
    flex-direction: column-reverse;
  }

  .landingPageWhatHaveItemText {
    width: auto;
  }
  .landingPageWhatHaveTitle {
    font-size: 38px;
    line-height: 40px;
  }

  .landingPageWhatHavePhotoArea {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }

  .landingPageWhatHavePhotoArea.second {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }

  .landingPageWhatHaveItemTitle {
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 30px;
  }
  .landingPageWhatHaveItemDescription {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .landingPageWhatHaveLinePhoto {
    display: none;
  }
  .landingPageWhatHaveLine {
    display: none;
  }
}

@media (max-width: 668px) {
  .landingPageWhatHaveItem {
    width: 90%;
    height: auto;
    flex-direction: column;
  }

  .landingPageWhatHaveItem.second {
    width: 90%;
    height: auto;
    flex-direction: column-reverse;
  }

  .landingPageWhatHaveItemText {
    width: auto;
  }
  .landingPageWhatHaveTitle {
    font-size: 32px;
    line-height: 36px;
  }

  .landingPageWhatHavePhotoArea {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }

  .landingPageWhatHavePhotoArea.second {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }

  .landingPageWhatHaveItemTitle {
    font-size: 24px;
    line-height: 28px;
  }
  .landingPageWhatHaveItemDescription {
    font-size: 16px;
  }

  .landingPageWhatHaveLinePhoto {
    display: none;
  }
  .landingPageWhatHaveLine {
    display: none;
  }
}

@media (max-width: 498px) {
  .landingPageWhatHaveItem {
    width: 90%;
    height: auto;
  }
  .landingPageWhatHaveTitle {
    font-size: 28px;
    width: 90%;
    line-height: 32px;
  }

  .landingPageWhatHaveItemTitle {
    font-size: 22px;
    line-height: 26px;
  }
  .landingPageWhatHaveItemDescription {
    font-size: 14px;
  }

  .landingPageWhatHavePhotoArea {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }

  .landingPageWhatHavePhotoArea.second {
    width: 100%;
    height: auto;
    margin-top: 50px;
    justify-content: center;
  }
  .landingPageWhatHavePhoto {
    max-width: 270px;
    max-height: 230px;
  }
}
