@import url("../../../Constants/Colors.css");

.welcomeContactContainer {
  background-color: var(--black);
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcomeContactInner {
  width: 1100px;
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.welcomeWhatsapp,
.welcomeTelegram {
  width: 500px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.whatsappIcon,
.telegramIcon {
  height: 70px;
  color: var(--primary-color);
  cursor: pointer;
}

.welcomeWhatsappText,
.welcomeTelegramText {
  color: var(--light-gray);
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.welcomeWhatsappIcon,
.welcomeTelegramIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.welcomeWhatsappIconText,
.welcomeTelegramIconText {
  font-size: 18px;
  margin-left: 10px;
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
}

.welcomeContactTitleArea {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: start;
  justify-content: center;
}

.welcomeContactTitle {
  font-size: 48px;
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
}

@media (max-width: 1238px) {
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 16px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 400px;
  }
  .welcomeContactInner {
    width: 850px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 16px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 60px;
  }
}

@media (max-width: 938px) {
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 14px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 300px;
    height: 140px;
  }
  .welcomeContactInner {
    width: 700px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 14px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 50px;
  }
}

@media (max-width: 738px) {
  .welcomeContactContainer {
    width: 100%;
    height: 700px;
  }
  .welcomeContactInner {
    width: 400px;
    height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 18px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 400px;
    height: 170px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 16px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 60px;
  }
  .welcomeContactTitleArea {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 120px;
  }
  .welcomeContactTitle {
    font-size: 40px;
    text-align: start;
  }
}

@media (max-width: 538px) {
  .welcomeContactContainer {
    width: 100%;
    height: 550px;
  }
  .welcomeContactInner {
    width: 350px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 16px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 350px;
    height: 150px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 14px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 50px;
  }
  .welcomeContactTitleArea {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .welcomeContactTitle {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 438px) {
  .welcomeContactContainer {
    width: 100%;
    height: 500px;
  }
  .welcomeContactInner {
    width: 330px;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 16px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 340px;
    height: 135px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 14px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 50px;
  }
}

@media (max-width: 368px) {
  .welcomeContactContainer {
    width: 100%;
    height: 470px;
  }
  .welcomeContactInner {
    width: 280px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .welcomeWhatsappText,
  .welcomeTelegramText {
    font-size: 14px;
  }
  .welcomeWhatsapp,
  .welcomeTelegram {
    width: 280px;
    height: 130px;
  }
  .welcomeWhatsappIconText,
  .welcomeTelegramIconText {
    font-size: 12px;
  }
  .whatsappIcon,
  .telegramIcon {
    height: 40px;
  }
}
