@import url('../../../Constants/Colors.css');

:root {
  --landing-page-header-background-url: url('/public/assets/Backgrounds/Landing_Page_Header_Background.png');
}

.landingPageHeaderContainer * {
  font-family: 'Helvatica', sans-serif;
}

.landingPageHeaderContainer {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.59) 0%,
      #0c0c0c 100%
    ),
    var(--landing-page-header-background-url) !important;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPageHeaderInner {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.landingPageHeaderTitle {
  text-align: center;
  font-size: 46px;
  line-height: 65px;
  font-weight: 600;
}

.landingPageHeaderTitleWhite {
  color: var(--white);
}

.landingPageHeaderTitleRed {
  color: var(--primary-color);
}

.landingPageHeaderSubtitle {
  font-size: 20px;
  color: var(--light-gray);
  margin-top: 50px;
  letter-spacing: 0.8px;
  text-align: center;
}

.content {
  transition: opacity 100ms ease-out;
}

.content.hidden {
  opacity: 0;
}

.landingPageHeaderVideo {
  width: 840px;
  height: 473px;
  margin-top: 60px;
}

.landingPageHeaderButton {
  width: 400px;
  height: 50px;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 30px;
  color: var(--white);
  font-size: 18px;
  letter-spacing: 0.7px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  margin-top: 90px;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingPageHeaderButton:hover {
  background-color: transparent;
}

.landingPageHeaderButton:hover .landingPageHeaderButtonIcon {
  transform: translateX(5px);
}

.landingPageHeaderButtonIcon {
  font-size: 16px;
  margin-left: 5px;
  transition: 300ms all;
}

.landingPageHeaderOgrenci {
  width: 450px;
  object-fit: cover;
  margin-top: 20px;
}

.landingPageHeaderUtilsContainer {
  width: 1100px;
  height: 150px;
  margin-top: 70px;
}

.landingPageHeaderUtilsCizgi {
  object-fit: cover;
  width: 1100px;
}

.landingPageHeaderUtils {
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.landingPageHeaderUtil {
  flex: 1 1 20%; /* Default item width */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  letter-spacing: 0.7px;
}

.landingPageHeaderUtilIcon {
  max-width: 40px;
  max-height: 40px;
  object-fit: cover;
  margin-right: 10px;
}

@media (max-width: 1168px) {
  .landingPageHeaderTitle {
    font-size: 40px;
    line-height: 60px;
    font-weight: 600;
  }
  .landingPageHeaderSubtitle {
    font-size: 18px;
    letter-spacing: 0.6px;
  }
  .landingPageHeaderVideo {
    width: 672px;
    height: 378px;
  }
  .landingPageHeaderUtilsContainer {
    width: 900px;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 900px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
  }
  .landingPageHeaderUtil {
    font-size: 16px;
    letter-spacing: 0.5px;
  }

  .landingPageHeaderUtilIcon {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
  }
}

@media (max-width: 1168px) {
  .landingPageHeaderTitle {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
  }
  .landingPageHeaderSubtitle {
    font-size: 16px;
    margin-top: 40px;
  }
  .landingPageHeaderVideo {
    width: 560px;
    height: 315px;
  }

  .landingPageHeaderButton {
    width: 370px;
    height: 45px;
    font-size: 16px;
    margin-top: 70px;
  }

  .landingPageHeaderButtonIcon {
    font-size: 15px;
  }

  .landingPageHeaderOgrenci {
    width: 400px;
    margin-top: 20px;
  }

  .landingPageHeaderUtilsContainer {
    width: 720px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 720px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
  }
  .landingPageHeaderUtil {
    font-size: 16px;
    letter-spacing: 0.5px;
    flex: 1 1 calc(50% - 20px);
    height: 50%;
  }

  .landingPageHeaderUtilIcon {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
  }
}

@media (max-width: 768px) {
  .landingPageHeaderTitle {
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
  }
  .landingPageHeaderSubtitle {
    font-size: 16px;
    margin-top: 40px;
    width: 500px;
  }
  .landingPageHeaderVideo {
    width: 448px;
    height: 252px;
  }

  .landingPageHeaderButton {
    height: 42px;
    margin-top: 50px;
  }

  .landingPageHeaderUtilsContainer {
    height: 180px;
    width: 600px;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 600px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
  }
  .landingPageHeaderUtil {
    font-size: 14px;
    letter-spacing: 0.5px;
    flex: 1 1 calc(50% - 20px);
    height: 50%;
  }

  .landingPageHeaderUtilIcon {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
  }
}

@media (max-width: 638px) {
  .landingPageHeaderTitle {
    font-size: 26px;
    line-height: 40px;
  }
  .landingPageHeaderSubtitle {
    font-size: 14px;
    margin-top: 35px;
    width: 450px;
  }
  .landingPageHeaderVideo {
    width: 336px;
    height: 189px;
  }

  .landingPageHeaderButton {
    height: 40px;
    font-size: 14px;
    width: 300px;
    letter-spacing: 0px;
    margin-top: 50px;
    font-weight: 500;
  }
  .landingPageHeaderOgrenci {
    width: 350px;
  }

  .landingPageHeaderUtilsContainer {
    height: 300px;
    width: 400px;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 400px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
    flex-direction: column;
  }
  .landingPageHeaderUtil {
    font-size: 14px;
    letter-spacing: 0.5px;
    flex: 1 1 20%;
    height: 100%;
  }

  .landingPageHeaderUtilIcon {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
  }
}

@media (max-width: 498px) {
  .landingPageHeaderInner {
    padding-top: 50px;
  }
  .landingPageHeaderTitle {
    font-size: 22px;
    line-height: 36px;
  }
  .landingPageHeaderSubtitle {
    font-size: 14px;
    margin-top: 35px;
    width: 350px;
    line-height: 18px;
  }
  .landingPageHeaderVideo {
    width: 336px;
    height: 189px;
  }

  .landingPageHeaderButton {
    height: 40px;
    font-size: 14px;
    width: 300px;
    letter-spacing: 0px;
    margin-top: 50px;
    font-weight: 500;
  }
  .landingPageHeaderOgrenci {
    width: 350px;
  }

  .landingPageHeaderUtilsContainer {
    height: 250px;
    width: 350px;
    margin-top: 50px;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 350px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
    flex-direction: column;
  }
  .landingPageHeaderUtil {
    font-size: 14px;
    letter-spacing: 0.5px;
    flex: 1 1 20%;
  }

  .landingPageHeaderUtilIcon {
    max-width: 35px;
    max-height: 35px;
    object-fit: cover;
  }
}

@media (max-width: 400px) {
  .landingPageHeaderInner {
    padding-top: 40px;
  }
  .landingPageHeaderTitle {
    font-size: 18px;
    line-height: 30px;
  }
  .landingPageHeaderSubtitle {
    font-size: 13px;
    margin-top: 35px;
    width: 300px;
  }
  .landingPageHeaderVideo {
    width: 300px;
    height: 165px;
  }

  .landingPageHeaderUtilsContainer {
    height: 230px;
    width: 300px;
    margin-top: 50px;
  }
  .landingPageHeaderOgrenci {
    width: 300px;
  }

  .landingPageHeaderUtilsCizgi {
    object-fit: cover;
    width: 300px;
  }

  .landingPageHeaderUtils {
    justify-content: space-around;
    flex-direction: column;
  }
  .landingPageHeaderUtil {
    font-size: 12px;
    letter-spacing: 0.5px;
    flex: 1 1 20%;
  }

  .landingPageHeaderUtilIcon {
    max-width: 30px;
    max-height: 30px;
    object-fit: cover;
  }
}
