@import url(../../../Constants/Colors.css);

:root {
  --login-background-url: url("/public/assets/Backgrounds/Login_Background.webp");
}

.newPasswordContent {
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.55) 1%,
      rgba(0, 0, 0, 0.97) 100%
    ),
    var(--login-background-url) !important;
  background-size: cover; /* Arka planın tamamen kapsamasını sağlar */
  background-position: center; /* Arka planın merkezlenmesini sağlar */
  background-repeat: no-repeat; /* Arka planın tekrar etmesini engeller */
}

.newPasswordBox {
  width: 420px;
  height: 300px;
  background-color: var(--white);
  border-radius: 20px;
  box-shadow: 1px 1px 10px 1px #c6c6c625;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newPasswordLeft {
  width: 320px;
  height: 260px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.newPasswordLogo {
  width: 150px;
  height: 150px;
  border-radius: 2000px;
}

.newPasswordForm {
  width: 320px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.newPasswordHeaderArea {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.newPasswordHeader {
  font-size: 38px;
  font-weight: 700;
  color: var(--black);
  line-height: 60px;
}
.newPasswordSubtitle {
  font-size: 18px;
  color: var(--gray);
  line-height: 24px;
}

.newPasswordFormArea {
  width: 320px;
  height: 280px;
}

.newPasswordUsernameArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
}

.newPasswordUsername {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: 20px;
}

.newPasswordPassArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95px;
  justify-content: space-between;
}

.newPasswordButtonArea {
  width: 100%;
  display: flex;
  align-items: center;
  height: auto;
}

.newPasswordRegister {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.newPasswordUsernameInput {
  max-width: 300px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: #eaeaea;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-weight: 500;
  margin-bottom: 5px;
}

.newPasswordPassInput {
  max-width: 300px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 5px;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: #eaeaea;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-weight: 500;
}

.newPasswordPass {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.newPasswordnewPass {
  font-size: 14px;
  color: var(--gray);
  transition: all 200ms;
  margin-bottom: 5px;
  cursor: pointer;
}

.newPasswordnewPass:hover {
  color: var(--primary-color);
}

.newPasswordButton {
  width: 320px;
  height: 45px;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  font-weight: 500;
  box-shadow: 1px 1px 10px 1px #ff00004c;
  cursor: pointer;
}

/* .newPasswordButton:hover {
  border: 2px var(--primary-color) solid;
  background-color: var(--white);
  color: var(--black);
} */

.newPasswordSub {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray);
  transition: all 200ms;
}

.newPasswordSub:hover {
  color: var(--primary-color);
}

.newPasswordRegisterText {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray);
}

.newPasswordQuest {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
}

.newPasswordRightText {
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
  margin-top: 30px;
}

.newPasswordRightInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 360px;
  flex-direction: column;
}

.newPasswordRightUp {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.newPasswordRightDown {
  width: 100%;
  height: 50%;
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animasyonu uygulayan CSS sınıfı */
.fade-in-left-500 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 500ms forwards;
}

.fade-in-left-1000 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 1000ms forwards;
}

.fade-in-left-1500 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 1750ms forwards;
}

.fade-in-right-1000 {
  opacity: 0;
  animation: fadeInFromRight 1150ms ease-in-out 1150ms forwards;
}

@media (max-width: 538px) {
  .newPasswordBox {
    width: 300px;
    height: 280px;
    background-color: var(--white);
    border-radius: 20px;
    box-shadow: 1px 1px 10px 1px #c6c6c625;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .newPasswordLeft {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .newPasswordForm {
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .newPasswordFormArea {
    width: 250px;
    height: 230px;
  }
  .newPasswordUsername {
    font-size: 16px;
  }
  .newPasswordUsernameInput {
    height: 34px;
    font-size: 14px;
  }
  .newPasswordButton {
    height: 40px;
    font-size: 14px;
  }
  .newPasswordSub {
    font-size: 14px;
  }
}
