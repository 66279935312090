@import url("../../../Constants/Colors.css");

.profileContainer {
  width: 100%;
  height: 1500px;
  background-color: var(--black);
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileInner {
  width: 850px;
  height: 1420px;
  display: flex;
  flex-direction: column;
}

.profileLeft,
.profileAlt {
  display: flex;
  width: 850px;
  flex-direction: column;
}

.profilePassword {
  display: flex;
  flex-direction: column;
  width: 850px;
}

.profileButtonArea {
  width: 850px;
  margin-top: 50px;
}

.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 200px;
}

.profileImage {
  width: 200px;
  height: 200px;
  display: flex;
}

.profileIcon {
  color: var(--white);
  font-size: 20px;
  transition: all 200ms;
  cursor: pointer;
}

.profileIcon:hover {
  color: var(--primary-color);
  transition: all 200ms;
}

.profileContact {
  width: 850px;
}

.profileContactTitleArea {
  width: 530px;
  height: 80px;
  display: flex;
  align-items: flex-end;
}

.profileContactTitle {
  font-size: 30px;
  font-weight: 500;
  color: var(--primary-color);
}

.profileContactDetails {
  width: 850px;
  display: flex;
  flex-direction: column;
}

.profileContactDetails.adress {
  width: 850px;
  display: flex;
  flex-direction: row;
}

.profileContactDetailsLine {
  width: 850px;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileContactDetailsLine.last {
  width: 580px;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profileContactDetailsLine.adress {
  display: flex;
  flex-direction: column;
}

.profileName {
  margin-right: 60px;
  width: 240px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileSurname {
  margin-right: 60px;
  width: 240px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileAgency {
  width: 240px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profileNameHeader,
.profileSurnameHeader {
  font-size: 20px;
  color: var(--white);
  font-weight: 500;
}
.profileNameInput,
.profileSurnameInput {
  width: 200px;
  height: 35px;
  border: 1px solid var(--light-gray);
  border-radius: 5px;
  outline: none;
  margin-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: #111;
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  font-weight: 500;
}

.profileButton {
  width: 220px;
  height: 55px;
  background-color: var(--primary-color);
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  cursor: pointer;
  transition: all 300ms; /* Renk değişimi geçiş süresi */
}

.profileButton:hover {
  background-color: transparent;
}
.profileContactDetailsLine .passwordIcon {
  position: absolute;
  right: 10px;
  top: 61%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--white);
}

.profileName,
.profileSurname,
.profileAgency {
  position: relative;
}

.profileNameInput,
.profileSurnameInput,
.profileNameInput {
  width: calc(100% - 30px);
}

.profileDisabledBox {
  opacity: 0;
}

.profileSoz {
  font-size: 22px;
  color: var(--white);
  font-weight: 500;
  margin-top: 40px;
}

.profileSozColored {
  color: var(--primary-color);
}

@media (max-width: 1138px) {
  .profileContactTitle {
    font-size: 24px;
  }
  .profileContactTitleArea {
    width: 400px;
    height: 80px;
  }
  .profileImg {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
  .profileImage {
    width: 530px;
    height: 150px;
  }
  .profileNameHeader,
  .profileSurnameHeader {
    font-size: 16px;
  }
  .profileNameInput,
  .profileSurnameInput {
    width: 170px;
  }
  .profileLeft,
  .profileAlt {
    width: 730px;
  }
  .profileName,
  .profileSurname {
    margin-right: 20px;
  }
  .profileContainer {
    height: 1550px;
  }
  .profileInner {
    width: 730px;
    height: 1350px;
  }
  .profilePassword {
    width: 730px;
  }

  .profileButtonArea {
    width: 730px;
  }
  .profileButton {
    font-size: 14px;
    width: 190px;
    height: 45px;
  }
  .profileContact {
    width: 730px;
  }
  .profileContactDetails {
    width: 730px;
  }
  .profileContactDetailsLine {
    width: 730px;
  }
  .profileContactDetailsLine .passwordIcon {
    right: 40px;
    top: 60%;
  }

  .profileSoz {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .profileContactTitleArea {
    width: 270px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  .profileContactTitle {
    font-size: 30px;
    font-weight: 600;
  }
  .profileImg {
    width: 150px;
    height: 150px;
    border-radius: 150px;
  }
  .profileImage {
    width: 270px;
    height: 150px;
    margin-bottom: 30px;
  }
  .profileNameHeader,
  .profileSurnameHeader {
    font-size: 20px;
  }
  .profileNameInput,
  .profileSurnameInput {
    width: 240px;
  }
  .profileLeft {
    width: 270px;
  }
  .profileAlt {
    width: 270px;
  }
  .profileName,
  .profileAgency,
  .profileSurname {
    display: flex;
    justify-content: start;
    height: 100px;
    width: 270px;
    margin-right: 0px;
  }
  .profileContainer {
    height: 2470px;
  }
  .profileInner {
    width: 270px;
    height: 2370px;
  }
  .profilePassword {
    width: 270px;
  }

  .profileButtonArea {
    width: 270px;
  }
  .profileButton {
    font-size: 16px;
    width: 220px;
    height: 53px;
  }
  .profileContact {
    width: 270px;
  }
  .profileContact.adress {
    width: 270px;
  }
  .profileContact.password {
    width: 270px;
  }
  .profileContactDetails {
    width: 270px;
  }

  .profileContactDetails.adres {
    width: 270px;
  }
  .profileContactDetails.password {
    width: 270px;
  }
  .profileContactDetailsLine {
    width: 270px;
    height: 350px;
    flex-wrap: wrap;
  }

  .profileContactDetailsLine.two {
    width: 270px;
    height: 350px;
    flex-wrap: wrap;
  }

  .profileButtonArea {
    width: 270px;
  }
  .profileContactDetailsLine .passwordIcon {
    right: 10px;
    top: 55%;
  }
  .profileSoz {
    font-size: 16px;
    line-height: 22px;
  }
}
