@import url("../../../Constants/Colors.css");

.adminPageContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminPageInner {
  width: 1100px;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.welcomeAdminPageHeader {
  color: var(--white);
  font-size: 70px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
}

.adminPageHeaderColored {
  color: var(--primary-color);
  white-space: nowrap;
}

.welcomeAdminPageButton {
  width: 200px;
  height: 50px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border-radius: 30px;
  border: 2px solid var(--primary-color);
  transition: 300ms all;
  cursor: pointer;
  margin-top: 90px;
}

.welcomeAdminPageButton:hover {
  background-color: transparent;
}

.welcomeAdminPageSubtitle {
  font-size: 22px;
  color: var(--light-gray);
  margin-top: 25px;
  font-weight: 500;
}

@media (max-width: 1168px) {
  .welcomeAdminPageHeader {
    font-size: 56px;
  }
  .welcomeAdminPageSubtitle {
    font-size: 20px;
  }
  .welcomeAdminPageButton {
    width: 180px;
    height: 45px;
    font-size: 16px;
    margin-top: 70px;
  }
}

@media (max-width: 838px) {
  .welcomeAdminPageHeader {
    font-size: 44px;
  }
  .welcomeAdminPageSubtitle {
    font-size: 16px;
  }
  .welcomeAdminPageButton {
    width: 160px;
    height: 40px;
    font-size: 15px;
    margin-top: 60px;
  }
}

@media (max-width: 638px) {
  .welcomeAdminPageHeader {
    font-size: 42px;
    text-align: left;
    line-height: 45px;
  }
  .welcomeAdminPageSubtitle {
    font-size: 16px;
    line-height: 20px;
    margin-top: 60px;
  }
  .welcomeAdminPageButton {
    width: 160px;
    height: 40px;
    font-size: 15px;
    margin-top: 60px;
  }

  .adminPageInner {
    width: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}

@media (max-width: 438px) {
  .welcomeAdminPageHeader {
    font-size: 38px;
  }
  .adminPageInner {
    width: 300px;
  }
}
