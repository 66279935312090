@import url("../../../Constants/Colors.css");

.landingPageMemberContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPageMemberInner {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPageMemberBox {
  width: 450px;
  height: auto;
  background-color: #252525;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.landingPageMemberTitle {
  font-size: 36px;
  color: var(--white);
  font-weight: 700;
}

.landingPageMemberTitleColored {
  color: var(--primary-color);
}
.landingPageMemberContent {
  width: 85%;
  height: auto;
  margin-top: 15px;
}

.landingPageMemberIcon {
  margin-right: 10px;
  font-size: 30px;
  color: var(--primary-color);
}

.landingPageMemberLine {
  display: flex;
  margin-top: 20px;
}

.landingPageMemberText {
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  line-height: 25px;
}

.landingPageMemberErisim {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  margin-top: 50px;
}

.landingPageMemberPrice {
  font-size: 36px;
  font-weight: 700;
  color: var(--light-gray);
  margin-top: 10px;
}

.landingPageMemberPriceColored {
  color: var(--primary-color);
}

.landingPageMemberButton {
  width: 350px;
  height: 50px;
  font-size: 20px;
  font-weight: 500;
  color: var(--white);
  background-color: var(--primary-color);
  border-radius: 30px;
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  margin-top: 50px;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.landingPageMemberButton:hover {
  background-color: transparent;
}

.landingPageMemberButtonIcon {
  font-size: 16px;
  margin-left: 5px;
  transition: 300ms all;
}
.landingPageMemberButton:hover .landingPageMemberButtonIcon {
  transform: translateX(5px);
}

.landingPageMemberFiyatArtisi {
  margin-top: 20px;
  font-size: 16px;
  color: var(--white);
  text-align: center;
}

@media (max-width: 968px) {
  .landingPageMemberContainer {
    padding-top: 70px;
  }
  .landingPageMemberBox {
    width: 400px;
  }
  .landingPageMemberTitle {
    font-size: 32px;
  }
  .landingPageMemberText {
    font-size: 18px;
  }
  .landingPageMemberButton {
    height: 40px;
    width: 300px;
    font-size: 16px;
  }
  .landingPageMemberPrice {
    font-size: 32px;
  }
  .landingPageMemberIcon {
    font-size: 22px;
  }
  .landingPageMemberButtonIcon {
    font-size: 16px;
  }
}

@media (max-width: 468px) {
  .landingPageMemberContainer {
    padding-top: 50px;
  }
  .landingPageMemberBox {
    width: 300px;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .landingPageMemberTitle {
    font-size: 28px;
  }
  .landingPageMemberText {
    font-size: 14px;
    line-height: 20px;
  }
  .landingPageMemberButton {
    height: 35px;
    width: 240px;
    font-size: 14px;
  }
  .landingPageMemberPrice {
    font-size: 28px;
  }
  .landingPageMemberErisim {
    font-size: 13px;
  }
  .landingPageMemberIcon {
    font-size: 22px;
  }
  .landingPageMemberButtonIcon {
    font-size: 14px;
  }
  .landingPageMemberFiyatArtisi {
    font-size: 14px;
    width: 250px;
  }
}
