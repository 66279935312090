@import url("../../../Constants/Colors.css");

.tutorialDetailContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorialDetailInner {
  max-width: 1300px;
  padding-top: 100px;
  padding-bottom: 100px;
  height: auto;
  margin: 0 auto;
}

.tutorialDetailGridContainer {
  display: grid;
  grid-template-columns: 1200px;
  grid-template-rows: auto;
}

.tutorialDetailBottomRow {
  display: grid;
  grid-template-columns: 800px 400px;
}

.tutorialDetailBottomRow .tutorialDetailLeftColumn {
  grid-column: 1;
}

.tutorialDetailBottomRow .tutorialDetailRightColumn {
  grid-column: 2;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.tutorialDetailHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.tutorialDetailTitle {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 50px;
  height: auto;
  text-align: center;
  display: flex;

  justify-content: flex-start;
}

.tutorialDetailImage {
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
}

.tutorialDetailImg {
  max-height: 350px;
  object-fit: cover;
  max-width: 700px;
}

.tutorialDetailDescription {
  color: var(--light-gray);
  font-size: 20px;
  font-weight: 500;
  height: auto;
  display: flex;
  text-align: center;
  align-items: flex-end;
  min-height: 60px;
  padding: 20px 0px 20px 0px;
}

.tutorialDetailButton {
  background-color: var(--primary-color);
  width: 320px;
  height: 55px;
  border-radius: 50px;
  border: 2px solid var(--primary-color);
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
  outline: none;
  transition: 300ms all;
  cursor: pointer;
  margin-top: 30px;
}

.tutorialDetailButton:hover {
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.tutorialContentArea {
  margin-top: 50px;
  background-color: var(--white);
  border-radius: 20px;
  width: 700px;
}

.tutorialDetailContentHeader {
  margin-top: 150px;
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
}

.tutorialDetailCommentHeader {
  margin-top: 150px;
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
  text-align: end;
}

.tutorialContentListItem {
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
}

.tutorialContentInner {
  width: 600px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tutorialContentLeft,
.tutorialContentRight {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  color: var(--black);
}

.tutorialContentLeft {
  max-width: 510px;
}

.tutorialContentRight {
  display: flex;
  justify-content: end;
  color: var(--gray);
}

.tutorialContentHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 90px;
}

.tutorialContentLeftIcon {
  font-size: 24px;
  margin-right: 15px;
}

.tutorialContentRightIcon {
  font-size: 24px;
  margin-left: 15px;
}

.tutorialContentHeaderTitle {
  font-size: 30px;
  font-weight: 700;
  color: var(--primary-color);
}

.tutorialContentHeaderInner {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tutorialContentHeaderIcon {
  transition: transform 0.3s ease;
}

.tutorialContentHeaderIcon.rotate {
  transform: rotate(90deg);
}

.categoryIcon.rotate{
  transform: rotate(90deg);
}

.tutorialContentList {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.tutorialContentList.expand {
  max-height: 500px; /* Adjust based on your content length */
  overflow-y: auto;
  box-shadow: 0px -1px 0 0 rgb(70, 70, 70);
}

.categoryContent{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.categoryContent.expand{
  max-height: 10000px;
}

.category{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
}
.categoryInner{
  width: 600px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  font-weight: 600;
  color: var(--black);
}

.categoryIcon{
  font-size: 16px;
  transition: transform 0.3s ease;
}

/* Adding styles for scrollbar */
.tutorialContentList.expand::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white); /* Scrollbar background color */
}

.tutorialContentList.expand::-webkit-scrollbar-thumb {
  background-color: var(--gray); /* Scrollbar thumb color */
  border-radius: 10px;
  border: none;
}

.tutorialContentList.expand::-webkit-scrollbar-track {
  background-color: var(--white); /* Scrollbar track background color */
  border-radius: 10px;
}

.tutorialContentList.expand::-webkit-scrollbar-corner {
  background-color: var(--white);
}

.tutorialContentList.expand::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

.noVideosMessage {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: var(--gray);
  font-weight: 600;
}

.tutorialDetailCommentHeader {
  margin-top: 150px;
  color: var(--white);
  font-size: 24px;
  font-weight: 500;
}

.tutorialCommentArea {
  margin-top: 50px;
  background-color: var(--white);
  border-radius: 20px;
  width: 350px;
}

.tutorialCommentItem {
  display: flex;
  width: 350px;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  height: auto;
}

.tutorialCommentItem:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last comment item */
}

.tutorialCommentPhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.tutorialCommentContent {
  flex: 1;
}

.tutorialCommentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.tutorialCommentName {
  font-weight: 600;
  color: var(--black);
  font-size: 14px;
}

.tutorialCommentDate {
  font-size: 12px;
  color: var(--gray);
}

.tutorialCommentText {
  font-size: 16px;
  color: var(--black);
  line-height: 17px;
  width: 95%;
}

.commentAltGroupIcon {
  width: 5%;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.noCommentsMessage {
  text-align: center;
  font-size: 18px;
  color: var(--gray);
  padding-bottom: 50px;
  padding-top: 50px;
  font-weight: 600;
}

.tutorialCommentHeaderInner {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tutorialCommentListInner {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tutorialLeaveCommentArea {
  margin-top: 50px;
  background-color: var(--white);
  border-radius: 20px;
  width: 350px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  height: auto; /* Allow it to grow with content */
}

.tutorialLeaveCommentUpArea {
  width: 305px;
  display: flex;
  align-self: flex-end;
  justify-content: flex-start;
  align-items: flex-end;
  height: 50%;
}

.tutorialLeaveCommentDownArea {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 30px;
  padding-top: 30px;
}

.tutorialLeaveCommentDownInner {
  display: flex;
  flex-direction: row; /* Ensure content stacks vertically */
  border-bottom: 2px solid var(--black);
  width: 260px;
}

.commentInput {
  border: none;
  outline: none;
  font-size: 16px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--black);
  resize: none; /* Prevent resizing by the user */
  overflow: hidden; /* Hide scrollbar */
  min-height: 30px; /* Set initial height to 30px */
  height: 30px; /* Set initial height to 30px */
  box-sizing: border-box;
  width: 100%; /* Take full width of the container */
  background-color: var(--white);
}

.tutorialLeaveCommentHeader {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
  margin-top: 20px;
}

.tutorialLeaveCommentDownInner span {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  font-weight: 500;
  cursor: pointer;
  color: var(--gray);
}

.commentAltGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.popup {
  position: absolute;
  display: block;
  bottom: 0;
  right: 0;
  background-color: #d8d8d8;
  color: var(--black);
  padding: 10px;
  border-radius: 5px;
  z-index: 1000; /* Adjust z-index as needed */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 120px; /* Adjust width as needed */
  cursor: pointer;
}

.commentAltGroupIcon {
  cursor: pointer;
}

.commentAltGroup {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.popup {
  position: absolute;
  bottom: 0px;
  padding: 15px;
}


@media (max-width: 1268px) {
  .tutorialDetailGridContainer {
    grid-template-rows: auto auto;
  }
  .tutorialDetailDescription {
    font-size: 18px;
    min-height: 80px;
  }
  .tutorialDetailGridContainer {
    grid-template-columns: 950px;
  }

  .tutorialDetailBottomRow {
    grid-template-columns: 600px 350px;
  }

  .tutorialContentInner,
  .tutorialContentHeaderInner,
  .tutorialCommentHeaderInner,
  .tutorialCommentListInner {
    width: 100%;
  }

  .tutorialContentArea {
    width: 550px;
  }

  .tutorialContentInner {
    width: 450px;
  }
  .tutorialContentHeaderInner {
    width: 450px;
  }
  .categoryInner{
    width: 450px;
    font-size: 18px;
  }

  .tutorialContentLeftIcon,
  .tutorialContentRightIcon {
    font-size: 20px;
  }

  .tutorialContentLeft {
    max-width: 350px;
  }

  .tutorialContentLeft,
  .tutorialContentRight {
    font-size: 16px;
  }
  .tutorialContentHeaderTitle {
    font-size: 25px;
  }

  .tutorialDetailTitle {
    font-size: 40px;
  }

  .tutorialDetailImg {
    max-height: 250px;
    object-fit: cover;
    max-width: 500px;
  }

  .tutorialDetailButton {
    width: 250px;
    height: 50px;
    font-size: 16px;
  }

  .tutorialCommentArea {
    width: 300px;
  }
  .tutorialLeaveCommentArea {
    width: 300px;
  }
  .tutorialLeaveCommentUpArea {
    width: 280px;
  }

  .tutorialCommentListInner {
    width: 250px;
  }
  .tutorialCommentItem {
    width: 300px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .tutorialCommentHeaderInner {
    width: 250px;
  }
  .tutorialCommentText {
    font-size: 14px;
    color: var(--black);
    line-height: 17px;
  }
}

@media (max-width: 1038px) {
  .tutorialDetailGridContainer {
    grid-template-rows: auto auto;
  }
  .tutorialDetailDescription {
    font-size: 16px;
    min-height: 70px;
  }
  .tutorialDetailGridContainer {
    grid-template-columns: 850px;
  }

  .tutorialDetailBottomRow {
    grid-template-columns: 500px 350px;
  }

  .tutorialContentInner,
  .tutorialContentHeaderInner,
  .tutorialCommentHeaderInner,
  .tutorialCommentListInner {
    width: 100%;
  }

  .tutorialContentArea {
    width: 450px;
  }

  .tutorialContentInner {
    width: 400px;
  }
  .tutorialContentHeaderInner {
    width: 400px;
  }
  .categoryInner{
    width: 400px;
    font-size: 18px;
  }


  .tutorialContentHeader {
    height: 70px;
  }

  .tutorialContentLeft,
  .tutorialContentRight {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
  }

  .tutorialContentLeft {
    max-width: 280px;
  }

  .tutorialContentLeftIcon,
  .tutorialContentRightIcon {
    font-size: 16px;
  }

  .tutorialContentHeaderTitle {
    font-size: 22px;
  }

  .tutorialDetailTitle {
    font-size: 40px;
  }
  .tutorialDetailImg {
    max-height: 300px;
    object-fit: cover;
    max-width: 420px;
  }

  .tutorialDetailButton {
    width: 250px;
    height: 50px;
    font-size: 16px;
  }

  .tutorialCommentArea {
    width: 300px;
  }

  .tutorialCommentListInner {
    width: 250px;
  }
  .tutorialCommentItem {
    width: 300px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .tutorialCommentHeaderInner {
    width: 250px;
  }
  .tutorialCommentText {
    font-size: 14px;
    color: var(--black);
    line-height: 17px;
  }
}

@media (max-width: 900px) {
  .tutorialDetailDescription {
    font-size: 16px;
    min-height: 60px;
    height: auto;
  }
  .tutorialDetailGridContainer,
  .tutorialDetailBottomRow {
    grid-template-columns: 500px;
    grid-template-rows: auto;
  }

  .tutorialDetailBottomRow .tutorialDetailLeftColumn,
  .tutorialDetailBottomRow .tutorialDetailRightColumn {
    grid-column: 1;
  }

  .tutorialDetailBottomRow .tutorialDetailRightColumn {
    display: flex;
    align-items: flex-start;
  }

  .tutorialContentArea,
  .tutorialCommentArea {
    width: 100%;
  }

  .tutorialContentInner,
  .tutorialContentHeaderInner,
  .tutorialCommentHeaderInner,
  .tutorialCommentListInner {
    width: 100%;
  }

  .tutorialContentInner {
    width: 100%;
  }
  .tutorialContentHeaderInner {
    width: 100%;
  }

  .tutorialContentLeft,
  .tutorialContentRight {
    font-size: 14px;
    font-weight: 500;
  }

  .tutorialContentLeftIcon,
  .tutorialContentRightIcon {
    font-size: 16px;
  }

  .tutorialContentHeaderTitle {
    font-size: 25px;
  }

  .tutorialDetailTitle {
    font-size: 48px;
    min-height: 50px;
    height: auto;
  }

  .tutorialDetailImg {
    max-height: 250px;
    object-fit: cover;
    max-width: 420px;
  }

  .tutorialDetailButton {
    width: 100%;
    height: 50px;
    font-size: 16px;
  }

  .tutorialCommentItem {
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .tutorialCommentHeaderInner {
    width: 100%;
  }
  .tutorialCommentText {
    font-size: 14px;
    color: var(--black);
    line-height: 17px;
  }
  .tutorialDetailButtonArea {
    height: 150px;
  }
  .tutorialDetailContentHeader {
    margin-top: 50px;
    font-size: 24px;
  }
  .tutorialDetailCommentHeader {
    margin-top: 50px;
    font-size: 24px;
    text-align: start;
  }

  .tutorialContentArea {
    width: 500px;
  }

  .tutorialContentInner {
    width: 450px;
  }
  .tutorialContentHeaderInner {
    width: 450px;
  }
  .categoryInner{
    width: 450px;
    font-size: 18px;
  }
  .tutorialDetailImage {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tutorialCommentArea {
    width: 500px;
  }

  .tutorialLeaveCommentArea {
    width: 500px;
  }
  .tutorialLeaveCommentUpArea {
    width: 450px;
  }

  .tutorialLeaveCommentDownInner {
    width: 400px;
  }

  .tutorialCommentListInner {
    width: 450px;
  }
  .tutorialCommentItem {
    width: 500px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .tutorialCommentHeaderInner {
    width: 450px;
  }
}

@media (max-width: 538px) {
  .tutorialDetailDescription {
    font-size: 14px;
    height: auto;
    min-height: 50px;
    padding: 0px;
  }
  .tutorialDetailGridContainer,
  .tutorialDetailBottomRow {
    grid-template-columns: 300px;
    grid-template-rows: auto;
  }

  .tutorialDetailImage {
    width: 300px;
    padding: 0px;
    max-height: 250px;
  }
  .tutorialDetailImg {
    max-height: 250px;
    object-fit: cover;
    max-width: 300px;
  }
  .tutorialDetailTitle {
    font-size: 36px;
    padding: 0px;
  }
  .tutorialContentArea {
    width: 300px;
  }
  .tutorialContentLeft {
    max-width: 220px;
  }
  .tutorialContentRightIcon,
  .tutorialContentLeftIcon {
    display: none;
  }
  .tutorialContentInner {
    width: 260px;
  }
  .tutorialContentHeaderInner {
    width: 260px;
  }
  .categoryInner{
    width: 260px;
    font-size: 15px;
  }
  .tutorialCommentArea {
    width: 300px;
  }

  .tutorialLeaveCommentArea {
    width: 300px;
  }
  .tutorialLeaveCommentUpArea {
    width: 280px;
  }

  .tutorialLeaveCommentDownInner {
    width: 260px;
  }

  .tutorialCommentListInner {
    width: 260px;
  }
  .tutorialCommentItem {
    width: 300px;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .tutorialCommentHeaderInner {
    width: 260px;
  }
  .tutorialContentHeaderTitle {
    font-size: 20px;
  }
  .tutorialContentLeft {
    font-size: 13px;
  }
  .tutorialContentRight {
    font-size: 12px;
  }
  .tutorialCommentName {
    font-size: 13px;
  }
  .tutorialCommentDate {
    font-size: 12px;
  }
  .tutorialContentHeader {
    height: 60px;
  }
  .tutorialDetailInner {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .tutorialDetailButton {
    width: 250px;
    height: 40px;
    font-size: 14px;
  }
}
