@import url("../../../Constants/Colors.css");

.usersContainer {
  min-height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.usersInner {
  width: 1100px;
  min-height: 85vh;
  height: auto;
}

.usersHeader {
  font-size: 36px;
  font-weight: 600;
  color: var(--primary-color);
}

.usersItemContainer {
  display: flex;
  align-items: center;
  height: 60px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
}

.usersItemContainer:hover {
  background-color: #1f1f1f;
}

.usersItemInner {
  display: flex;
  width: 1100px;
  align-items: center;
  justify-content: space-between;
}

.usersItemHeader {
  font-size: 20px;
  font-weight: 500;
  width: 300px;
  color: var(--white);
}

.usersItemButton {
  width: 100px;
  height: 35px;
  font-size: 14px;
  border-radius: 30px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  transition: all 300ms;
  margin-left: 30px;
  text-transform: capitalize;
}

.usersItemButtonSecond {
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  font-size: 14px;
  border-radius: 30px;
  background-color: transparent;
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  margin-left: 30px;
  transition: all 300ms;
}

.usersItemButtons {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: center;
}

.usersListTitle {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
  width: 300px;
}

.usersListTitle.rol {
  text-align: center;
}

.usersListTitle.islem {
  text-align: end;
}

.usersListHeaderContainer {
  height: 60px;
  display: flex;
  justify-content: center;
}

.usersListHeaderInner {
  display: flex;
  justify-content: space-between;
  height: 30px;
  box-shadow: 0 2px 0px 0 var(--primary-color);
  width: 1100px;
}

.usersList {
  overflow-y: scroll;
  max-height: 60vh;
  height: auto;
}

.usersInnerIcon {
  display: none;
}

.itemBanButton {
  width: 130px;
  height: 40px;
  background-color: var(--primary-color);
  border-radius: 5px;
  transition: all 300ms;
  border: 2px solid var(--primary-color);
  color: var(--white);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.itemBanButton:hover {
  background-color: transparent;
}

.usersItemIslem {
  width: 300px;
  display: flex;
  justify-content: end;
}

.usersModal {
  position: fixed;
  top: 0;
  padding-left: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.usersModalContent {
  background-color: var(--white);
  padding: 20px;
  border-radius: 5px;
  width: 900px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 86vh;
}

.addUserModalParameter.last {
  margin-bottom: 10px;
}

.modalInner {
  display: flex;
  flex-direction: row;
}

.modalSectionLeft,
.modalSectionRight {
  width: 50%;
}

.usersModalHeader {
  font-size: 30px;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.usersModalClose {
  top: 0px;
  right: 0px;
  cursor: pointer;
  font-size: 28px;
  color: #656565;
}

.usersModalClose:hover {
  color: var(--black);
}

.modalParameter {
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--gray);
}

.addUserModalParameter {
  width: 350px;
  font-size: 16px;
  margin-bottom: 30px;
  color: var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modalValue {
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
}

.modalValue.capitalize {
  text-transform: capitalize;
}

.modalValue.adressSokak {
  line-height: 20px;
}

.modalSectionLeftHeader,
.modalSectionRightHeader {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.modalSectionLeftHeader,
.modalSectionRightHeader:first-child {
  margin-bottom: 40px;
  margin-top: 20px;
}

.modalBanButton {
  width: 130px;
  height: 40px;
  background-color: var(--primary-color);
  border-radius: 5px;
  transition: all 300ms;
  border: 2px solid var(--primary-color);
  color: var(--white);
  cursor: pointer;
}

.modalBanButton:hover {
  background-color: transparent;
  color: var(--black);
}

.usersHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.usersSearchContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.usersSearchBar {
  padding: 5px 35px 5px 20px;
  width: 200px;
  height: 30px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-radius: 20px;
  border: 2px solid var(--primary-color);
  background-color: var(--black);
  color: var(--white);
}

.usersSearchBar::placeholder {
  color: var(--light-gray);
}

.usersSearchIcon {
  margin-left: -30px;
  color: var(--light-gray);
  cursor: pointer;
}

.addUserIconContainer {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-radius: 50%;
}

.addUserIconInner {
  width: 60px;
  height: 60px;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 300ms;
}

.addUserIconInner:hover {
  background-color: transparent;
}

.addUserIcon {
  color: var(--white);
  font-size: 26px;
}

/* users.css */

/* Diğer stiller */

/* Popup container */
.addUserPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Arka planı karartan overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Popup'ın diğer içeriklerin üstünde olmasını sağlar */
}

.addUserPopupContent {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Popup'ın içindeki öğeleri düzenlemek için */
}

.addUserPopup h2 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.addUserInput {
  width: 200px;
  border: 1px solid var(--black);
  background-color: var(--white);
  border-radius: 3px;
  height: 33px;
  outline: none;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.addUserButton {
  width: 170px;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--primary-color);
  color: var(--white);
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 300ms;
}

.addUserButton:hover {
  background-color: transparent;
  color: var(--black);
}

.addUserPopupClose {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: #888;
}

.asterisk-colored {
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1238px) {
  .usersInner {
    width: 700px;
  }

  .usersItemInner {
    width: 700px;
  }

  .usersItemButtons {
    width: 340px;
  }

  .usersItemButton,
  .usersItemButtonSecond {
    height: 27px;
  }

  .usersListTitle {
    width: 230px;
  }

  .usersItemButtons {
    width: 210px;
  }

  .usersItemHeader {
    width: 210px;
    font-size: 18px;
    font-weight: 500;
  }

  .usersItemIslem {
    width: 210px;
  }
}

@media (max-width: 1038px) {
  .usersModalContent {
    width: 700px;
    height: 500px;
  }

  .modalSectionLeft,
  .modalSectionRight {
    width: 48%;
  }

  .modalInner {
    justify-content: space-between;
  }

  .modalParameter {
    font-size: 14px;
  }

  .modalValue {
    font-size: 16px;
  }

  .modalBanButton {
    width: 110px;
    height: 36px;
  }
  .addUserModalParameter {
    width: 320px;
    font-size: 16px;
    margin-bottom: 30px;
    color: var(--gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .addUserInput {
    width: 200px;
  }
}

@media (max-width: 838px) {
  .usersHeader {
    font-size: 36px;
    margin-bottom: 30px;
  }
  .usersInner {
    width: 500px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .usersItemButtons {
    width: 120px;
    display: flex;
  }

  .usersItemButton,
  .usersItemButtonSecond {
    height: 27px;
  }

  .usersListTitle {
    width: 165px;
  }

  .usersItemButtons {
    width: 147px;
  }

  .usersItemHeader {
    width: 147px;
    font-size: 14px;
    font-weight: 500;
  }

  .usersItemIslem {
    width: 147px;
  }

  .itemBanButton {
    width: 80px;
    height: 30px;
    font-size: 14px;
  }

  .usersModalContent {
    width: 500px;
    height: auto; /* Make modal height auto for scrollability */
    max-height: 75vh; /* Set max height for modal */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .modalInner {
    flex-direction: column; /* Change to column layout */
  }

  .modalSectionLeft,
  .modalSectionRight {
    width: 100%; /* Full width for each section */
  }

  .modalParameter {
    font-size: 14px;
  }

  .modalValue {
    font-size: 16px;
  }

  .modalBanButton {
    width: 110px;
    height: 36px;
  }
  .usersHeaderContainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .usersSearchBar {
    width: 241px;
    height: 25px;
    font-size: 14px;
  }
  .usersList {
    overflow-y: scroll;
    height: 53vh;
  }
  .addUserModalParameter {
    width: 400px;
    font-size: 14px;
    margin-bottom: 30px;
    color: var(--gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .addUserIconContainer {
    bottom: 25px;
    right: 25px;
  }

  .addUserIconInner {
    width: 50px;
    height: 50px;
  }
  .addUserIcon {
    font-size: 22px;
  }
}

@media (max-width: 630px) {
  .usersList {
    overflow-y: scroll;
    height: 50vh;
  }

  .usersListTitle {
    width: 135px;
  }

  .usersListTitle.islem {
    display: none;
  }

  .usersListTitle.rol {
    text-align: end;
  }

  .usersListTitle {
    font-size: 15px;
  }

  .usersInner {
    width: 300px;
  }

  .usersItemIslem {
    display: none;
  }

  .usersItemButton {
    width: 80px;
    height: 25px;
    font-size: 12px;
    border-radius: 30px;
    font-weight: 500;
  }

  .usersItemButtonSecond {
    width: 80px;
    height: 25px;
    font-size: 12px;
    border-radius: 30px;
    font-weight: 500;
  }

  .usersItemContainer {
    padding-left: 10px;
    padding-right: 10px;
  }

  .usersItemButtons {
    width: 100px;
    display: flex;
    justify-content: flex-end;
  }

  .usersItemHeader {
    width: 170px;
    font-size: 14px;
    font-weight: 500;
  }

  .usersHeader {
    font-size: 26px;
    text-align: left;
    margin-bottom: 50px;
  }
  .usersModalContent {
    width: 300px;
    height: auto; /* Make modal height auto for scrollability */
    max-height: 75vh; /* Set max height for modal */
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .modalInner {
    flex-direction: column; /* Change to column layout */
  }

  .modalSectionLeft,
  .modalSectionRight {
    width: 100%; /* Full width for each section */
  }

  .modalParameter {
    font-size: 13px;
  }

  .modalValue {
    font-size: 14px;
  }

  .modalBanButton {
    width: 110px;
    height: 36px;
  }

  .usersHeaderContainer {
    align-items: flex-start;
    margin-bottom: 50px;
    flex-direction: column;
  }

  .usersSearchBar {
    width: 241px;
    height: 25px;
    font-size: 14px;
  }
  .addUserModalParameter {
    width: 300px;
    font-size: 13px;
    margin-bottom: 30px;
    color: var(--gray);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .addUserInput {
    width: 200px;
    height: 30px;
    font-size: 13px;
  }
  .usersModalHeader {
    font-size: 26px;
  }
  .addUserIconContainer {
    bottom: 20px;
    right: 20px;
  }

  .addUserIconInner {
    width: 45px;
    height: 45px;
  }
  .addUserIcon {
    font-size: 20px;
  }
}
