@import url(../Constants/Colors.css);

.footerContainer {
  width: 100%;
  height: 400px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -2px 2px -2px rgb(70, 70, 70);
}

.footerInner {
  width: 1200px;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerArea {
  width: 400px;
  height: 400px;
}

.footerLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerSocial {
  background-color: #aaa;
}

.footerImg {
  width: 350px;
  cursor: pointer;
}

.footerSubtitle {
  font-size: 16px;
  width: 350px;
  margin-top: 30px;
  color: var(--light-gray);
}

.footerInfoTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
}

.footerInfoInner {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerInfoList {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerInfoListItem {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: var(--light-gray);
  transition: all 300ms;
}

.footerInfoListLink {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: var(--light-gray);
  transition: all 300ms;
}

.footerInfoListLink:hover {
  color: var(--primary-color);
}

.footerInfoListItem:hover {
  color: var(--primary-color);
}

.footerBerat {
  font-weight: 500;
  cursor: pointer;
  transition: color 200ms;
  color: var(--primary-color);
}

.footerBerat:hover {
  color: var(--light-gray);
}

@media (max-width: 1338px) {
  .footerImg {
    width: 300px;
  }
  .footerSubtitle {
    font-size: 16px;
    width: 300px;
  }
  .footerArea {
    width: 350px;
  }
}

@media (max-width: 1138px) {
  .footerInfoListItem {
    font-size: 14px;
  }
  .footerInfoTitle {
    font-size: 20px;
  }
  .footerImg {
    width: 250px;
  }
  .footerSubtitle {
    font-size: 14px;
    width: 270px;
  }
  .footerArea {
    width: 300px;
  }
  .footerImg {
    width: 270px;
  }
}

@media (max-width: 938px) {
  .footerContainer {
    height: 600px;
  }
  .footerInner {
    height: 500px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .footerArea {
    height: 300px;
  }
  .footerLogo {
    order: 1;
    width: 570px; /* İlk öğeyi %100 genişlikte yapar */
    height: 200px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
  }
  .footerInfoInner {
    width: 200px;
  }
  .footerSubtitle {
    margin-top: 0px;
    margin-left: 30px;
    width: 270px;
  }
}

@media (max-width: 600px) {
  .footerContainer {
    height: 800px;
  }
  .footerInner {
    height: 750px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .footerLogo {
    width: 300px; /* İlk öğeyi %100 genişlikte yapar */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footerSubtitle {
    margin-top: 20px;
    margin-left: 0px;
    width: 270px;
  }
  .footerArea {
    height: 200px;
  }
  .footerImg {
    width: 270px;
  }
}
