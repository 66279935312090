@import url("../../../Constants/Colors.css");

.landingPageLastContainer {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.landingPageLastInner {
  width: 80%;
  height: 500px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.landingPageLastLeft,
.landingPageLastRight {
  width: 50%;
  height: 100%;
}

.landingPageLastTitle {
  font-size: 40px;
  font-weight: 700;
  color: var(--white);
}

.landingPageLastTitleColored {
  color: var(--primary-color);
  font-size: 40px;
  font-weight: 700;
}

.landingPageLastTitlePhoto {
  margin-top: 20px;
  width: 130px;
  object-fit: cover;
}

.landingPageLastText {
  font-size: 18px;
  color: var(--white);
  font-family: "Helvatica", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  line-height: 24px;
}

.slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Kaydırıcı yüksekliği */
  width: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--primary-color) !important;
  font-weight: 700 !important;
}

.landingPageLastRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 30px !important  ;
}

.slide {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1338px) {
  .slide-content {
    width: 400px;
    object-fit: contain;
  }
  .landingPageLastTitle {
    font-size: 36px;
  }

  .landingPageLastTitleColored {
    font-size: 36px;
  }

  .landingPageLastText {
    font-size: 16px;
    margin-top: 18px;
    line-height: 24px;
  }
}

@media (max-width: 1038px) {
  .slide-content {
    width: 350px;
    object-fit: contain;
  }
}

@media (max-width: 968px) {
  .landingPageLastContainer {
    height: 800px;
  }
  .landingPageLastInner {
    width: 90%;
    height: auto;
    flex-direction: column;
  }
  .landingPageLastLeft,
  .landingPageLastRight {
    width: 100%;
    padding-top: 50px;
  }
  .slide-content {
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 468px) {
  .slide-content {
    width: 250px;
  }
  .landingPageLastLeft {
    padding-top: 100px;
  }
  .landingPageLastRight {
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .landingPageLastTitle {
    font-size: 30px;
  }

  .landingPageLastTitleColored {
    font-size: 30px;
  }

  .landingPageLastText {
    font-size: 14px;
    margin-top: 16px;
    line-height: 24px;
  }
}
