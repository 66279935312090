@import url("../../../Constants/Colors.css");
@import url("https://fonts.googleapis.com/css2?family=Fjalla+One&family=Oswald:wght@200..700&display=swap");

.welcomeSectionContainer {
  width: 100%;
  height: 85vh;
  padding-top: 100px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeSectionInner {
  background-color: var(--black);
  height: 400px;
  width: 1100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.welcomeHeader {
  color: var(--primary-color);
  font-size: 72px;
  font-weight: 400;
  letter-spacing: 3px;
  font-family: "Oswald", sans-serif;
  text-align: center;
}

.welcomeHeaderColored {
  color: var(--white);
  font-family: "Oswald", sans-serif !important;
  font-weight: 400;
}

.welcomeSubtitle {
  color: var(--white);
  font-size: 28px;
  text-align: center;
  font-weight: 600;
  width: 1000px;
  line-height: 35px;
}

.welcomeDescription {
  color: var(--light-gray);
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.welcomeButton {
  width: 200px;
  height: 50px;
  background-color: var(--primary-color);
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
  border-radius: 30px;
  border: 2px solid var(--primary-color);
  transition: 300ms all;
  cursor: pointer;
}

.welcomeButton:hover {
  background-color: transparent;
}
@media (max-width: 1138px) {
  .welcomeHeader {
    font-size: 58px;
  }
  .welcomeSubtitle {
    font-size: 20px;
    width: 800px;
  }
  .welcomeDescription {
    font-size: 14px;
  }
  .welcomeButton {
    width: 170px;
    height: 43px;
    font-size: 16px;
  }
  .welcomeSectionContainer {
    height: 85vh;
  }
  .welcomeSectionInner {
    height: 330px;
  }
}

@media (max-width: 938px) {
  .welcomeHeader {
    font-size: 48px;
  }
  .welcomeSubtitle {
    font-size: 16px;
    width: 700px;
  }
  .welcomeDescription {
    font-size: 12px;
  }
  .welcomeSectionContainer {
    height: 85vh;
  }
  .welcomeSectionInner {
    height: 300px;
  }
}
@media (max-width: 738px) {
  .welcomeHeader {
    width: 450px;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    height: 100px;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeSubtitle {
    font-size: 16px;
    line-height: 23px;
    width: 700px;
    width: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeDescription {
    font-size: 12px;
    line-height: 23px;
    width: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeSectionContainer {
    height: 85vh;
  }
  .welcomeSectionInner {
    height: 400px;
  }
  .welcomeButton {
    width: 170px;
    height: 43px;
    font-size: 16px;
  }
  .welcomeButtonArea {
    width: 700px;
    width: 450px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 538px) {
  .welcomeSectionContainer {
    height: 85vh;
  }
  .welcomeHeader {
    width: 350px;
    font-size: 36px;
    display: flex;
    flex-direction: column;
    height: 90px;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeSubtitle {
    font-size: 16px;
    line-height: 22px;
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeDescription {
    font-size: 14px;
    line-height: 20px;
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeButton {
    width: 170px;
    height: 45px;
    font-size: 16px;
  }
  .welcomeButtonArea {
    width: 350px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
}

@media (max-width: 400px) {
  .welcomeHeader {
    width: 250px;
    font-size: 30px;
    display: flex;
    flex-direction: column;
    height: 70px;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeSubtitle {
    font-size: 14px;
    line-height: 22px;
    width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeDescription {
    font-size: 12px;
    line-height: 20px;
    width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
  .welcomeButton {
    width: 130px;
    height: 35px;
    font-size: 12px;
  }
  .welcomeButtonArea {
    width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
  }
}
