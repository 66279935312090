@import url("../../../Constants/Colors.css");

.egitimSetiContainer {
  min-height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.egitimSetiInner {
  background-color: var(--black);
  border-radius: 8px;
  width: 1000px;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
