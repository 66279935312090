@import url("../Constants/Colors.css");

.dropdownArea {
  width: 200px;
  height: 33px;
  border: 1px solid var(--black);
  border-radius: 3px;
  outline: none;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  background-color: var(--white);
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  font-weight: 500;
  color: var(--black);
}

.dropdownArea option {
  text-transform: capitalize;
}
