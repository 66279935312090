@import url("../../../Constants/Colors.css");

.landingPageNavbarList.landingPage {
  justify-content: flex-end;
}
.landingPageNavbarContainer {
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  position: sticky;
  z-index: 1000;
  box-shadow: 0 2px 2px -2px rgb(70, 70, 70);
  transition: background 0.3s ease;
  background-color: var(--black);
}

.landingPageNavbarInner {
  width: 65%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.landingPageNavbarLogoBanner {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPageNavbarLogo {
  cursor: pointer;
}

.landingPageNavLogo {
  width: 300px;
}

.landingPageNavbarContent {
  width: 45%;
}

.landingPageNavbarList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landingPageNavbarListItem:last-child {
  border: 2px solid #b8b8b8;
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 200ms linear;
  cursor: pointer;
}

.landingPageNavbarListItem:last-child:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 200ms linear;
}

.landingPageNavbarLink {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  transition: color 200ms linear;
  cursor: pointer;
}

@media (max-width: 1638px) {
  .landingPageNavbarContent {
    width: 45%;
  }

  .landingPageNavbarInner {
    width: 75%;
  }
  .landingPageNavbarLogoBanner {
    width: auto;
  }
}

@media (max-width: 1368px) {
  .landingPageNavbarLink {
    font-size: 15px;
  }
  .landingPageNavbarContent {
    width: 50%;
  }
  .landingPageNavbarListItem:last-child {
    border: 2px solid #b8b8b8;
    padding: 7px 25px;
  }
}

@media (max-width: 1168px) {
  .landingPageNavbarContent {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .landingPageNavbarLogo {
    font-size: 20px;
  }
  .landingPageNavbarInner {
    width: 75%;
  }
  .landingPageNavbarLogoBanner {
    width: auto;
  }
  .landingPageNavbarContent {
    width: auto;
  }
  .landingPageNavLogo {
    width: 250px;
  }
}

@media (max-width: 568px) {
  .landingPageNavLogo {
    width: 130px;
  }
  .landingPageNavbarListItem:last-child {
    border: 1px solid #b8b8b8;
    padding: 7px 20px;
    border-radius: 50px;
    transition: all 200ms linear;
    cursor: pointer;
  }

  .landingPageNavbarLink {
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width: 368px) {
  .landingPageNavLogo {
    width: 100px;
  }
  .landingPageNavbarListItem:last-child {
    border: 1px solid #b8b8b8;
    padding: 7px 20px;
    border-radius: 50px;
    transition: all 200ms linear;
    cursor: pointer;
  }

  .landingPageNavbarLink {
    font-size: 12px;
    font-weight: 400;
  }
}
