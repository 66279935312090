@import url("../../../Constants/Colors.css");

:root {
  --register-background-url: url("/public/assets/Backgrounds/Register_Background.png");
}

.registerContainer {
  width: 100%;
  min-height: calc(100vh - 100px);
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 1%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    var(--register-background-url) !important;
  background-size: cover; /* Arka planın tamamen kapsamasını sağlar */
  background-position: center; /* Arka planın merkezlenmesini sağlar */
  background-repeat: no-repeat; /* Arka planın tekrar etmesini engeller */
  padding-top: 80px;
  padding-bottom: 100px;
  flex-direction: column;
}

.registerInner {
  width: calc(75% - 100px);
  height: auto;
  background-color: var(--white);
  padding: 50px;
}

.registerTitle {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
  margin-bottom: 70px;
}
.registerTitleColored {
  color: var(--primary-color);
}

.registerInnerTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--black);
}

.registerLine {
  margin-top: 50px;
  margin-bottom: 50px;
  opacity: 0;
}

.registerProductPriceLine {
  margin-top: 40px;
  color: var(--black);
}

.registerAsterisks {
  color: var(--primary-color);
  font-weight: 500;
}

.registerName,
.registerSurname,
.registerPhone,
.registerMail,
.registerCountry,
.registerCity,
.registerDistrict,
.registerAdress,
.registerPostCode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--black);
}

.registerName input,
.registerSurname input,
.registerPhone input,
.registerMail input,
.registerCountry input,
.registerCity input,
.registerDistrict input,
.registerAdress input,
.registerPostCode input {
  width: 30vw;
  height: 40px;
  box-sizing: border-box;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 16px;
  caret-color: var(--primary-color);
  outline: none;
  border: 1px solid #999;
  color: var(--black);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}

.registerNameSurname,
.registerPhoneMail,
.registerCountryCity,
.registerDistrictAdress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  color: var(--black);
}

.registerPostCodeContainer {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  color: var(--black);
}

.registerNameSurname {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  color: var(--black);
}

.registerProductPriceHeader {
  height: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
}

.registerTotalPriceContainer {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
}

.registerProductPrice {
  height: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
}

.registerProductHeader,
.registerTotal,
.registerTotalPrice,
.registerPriceHeader {
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
}

.registerProduct,
.registerPrice {
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.registerProduct {
  margin-right: 10px;
}
.registerInner .formLabel {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
  color: var(--black);
}

.checkboxLabel {
  margin-left: 10px;
  line-height: 18px;
  font-size: 16px;
  font-weight: 400;
  color: var(--black);
}

.registerInformation.first {
  margin-top: 230px;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
}

.registerInformation {
  font-size: 16px;
  margin-top: 20px;
  line-height: 20px;
  color: var(--black);
}
.registerInformation.red {
  color: var(--primary-color);
  font-weight: 500;
}

.registerPrivacyPolicy,
.termsAndConditions {
  text-decoration: underline;
  cursor: pointer;
  color: var(--black);
}

.registerPrivacyPolicy:hover,
.termsAndConditions:hover {
  color: var(--primary-color);
}

#check {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkboxArea {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.registerButtonArea {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.registerButton {
  width: 200px;
  height: 50px;
  background-color: var(--primary-color);
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  color: var(--white);
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  transition: all 300ms;
}

.registerButton:hover {
  background-color: transparent;
  color: var(--black);
}

body.modal-open {
  overflow: hidden;
}

.registerModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.registerModalContent p {
  margin-bottom: 10px;
  line-height: 20px;
}

.registerModalContent h2 {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  max-width: 90%;
}

.registerModalContent {
  background: white;
  padding: 20px;
  max-width: 800px;
  width: 60%;
  position: relative;
  max-height: 90vh;
  overflow-y: scroll;
}

.registerModalContent::-webkit-scrollbar-track {
  background-color: var(--white);
}

.registerModalContent::-webkit-scrollbar-thumb {
  background-color: var(--light-gray);
  border: none;
}

.registerModalCloseButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 1368px) {
  .registerName input,
  .registerSurname input,
  .registerPhone input,
  .registerMail input,
  .registerCountry input,
  .registerCity input,
  .registerDistrict input,
  .registerAdress input,
  .registerPostCode input {
    width: 400px;
    height: 40px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    caret-color: var(--primary-color);
    outline: none;
    border: 1px solid #999;
    color: var(--black);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
  }
  .registerTitle {
    font-size: 44px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 70px;
  }
}

@media (max-width: 1238px) {
  .registerName input,
  .registerSurname input,
  .registerPhone input,
  .registerMail input,
  .registerCountry input,
  .registerCity input,
  .registerDistrict input,
  .registerAdress input,
  .registerPostCode input {
    width: 350px;
    height: 40px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    caret-color: var(--primary-color);
    outline: none;
    border: 1px solid #999;
    color: var(--black);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
  }
  .registerTitle {
    font-size: 44px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 70px;
  }
}

@media (max-width: 1098px) {
  .registerName input,
  .registerSurname input,
  .registerPhone input,
  .registerMail input,
  .registerCountry input,
  .registerCity input,
  .registerDistrict input,
  .registerAdress input,
  .registerPostCode input {
    width: 300px;
    height: 40px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    caret-color: var(--primary-color);
    outline: none;
    border: 1px solid #999;
    color: var(--black);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
  }
  .registerTitle {
    font-size: 40px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 70px;
  }
}

@media (max-width: 968px) {
  .registerLine {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .registerName,
  .registerSurname,
  .registerPhone,
  .registerMail,
  .registerCountry,
  .registerCity,
  .registerDistrict,
  .registerAdress,
  .registerPostCode {
    width: 100%;
  }

  .registerName input,
  .registerSurname input,
  .registerPhone input,
  .registerMail input,
  .registerCountry input,
  .registerCity input,
  .registerDistrict input,
  .registerAdress input,
  .registerPostCode input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    font-size: 16px;
    caret-color: var(--primary-color);
    outline: none;
    border: 1px solid #999;
    color: var(--black);
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
  }
  .registerTitle {
    font-size: 36px;
    font-weight: 700;
    color: var(--white);
    margin-bottom: 70px;
  }
  .registerNameSurname,
  .registerPhoneMail,
  .registerCountryCity,
  .registerDistrictAdress {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    color: var(--black);
  }

  .registerPostCodeContainer {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    color: var(--black);
  }

  .registerNameSurname {
    margin-top: 40px;
  }

  .registerSurname,
  .registerMail,
  .registerCity,
  .registerAdress {
    margin-top: 30px;
  }
  .registerInnerTitle {
    width: 100%;
  }
  .registerButtonArea {
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
  }
  .registerTitle {
    font-size: 34px;
    margin-bottom: 60px;
  }
  .registerModalContent {
    width: 70%;
  }
}

@media (max-width: 538px) {
  .registerInner {
    width: calc(85% - 100px);
    height: auto;
    background-color: var(--white);
    padding: 50px;
  }
  .registerTitle {
    font-size: 28px;
    margin-bottom: 60px;
    text-align: center;
  }
  .registerInner .formLabel {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
  }
  .registerInnerTitle {
    font-size: 20px;
  }
  .registerProductHeader,
  .registerTotal,
  .registerTotalPrice,
  .registerPriceHeader {
    font-size: 16px;
    font-weight: 500;
    color: var(--black);
  }

  .registerProduct,
  .registerPrice {
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
  }

  .checkboxLabel {
    margin-left: 10px;
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
    color: var(--black);
  }

  .registerInformation {
    line-height: 18px;
    font-size: 14px;
    color: var(--black);
    margin-top: 20px;
  }
  .registerInformation.first {
    margin-top: 230px;
    line-height: 18px;
    font-size: 14px;
    color: var(--black);
  }

  .registerButton {
    width: 170px;
    height: 45px;
    font-size: 16px;
  }
  .registerModalContent {
    width: 70%;
  }
  .registerModalContent p {
    margin-bottom: 10px;
    line-height: 18px;
    font-size: 14px;
  }
}

@media (max-width: 438px) {
  .registerTitle {
    font-size: 24px;
    margin-bottom: 60px;
    text-align: center;
  }
  .registerInner {
    width: calc(85% - 60px);
    padding: 30px;
  }
  .registerModalContent {
    width: 70%;
  }
}
