@import url("../../../Constants/Colors.css");

.egitimolusturContainer {
  min-height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.egitimolusturInner {
  background-color: var(--black);
  border-radius: 8px;
  width: 1000px;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.formGroup {
  margin-bottom: 20px;
}

.formTextInputs {
  display: flex;
  flex-direction: row;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

.formFileInputs {
  display: flex;
  flex-direction: row;
  height: 400px;
  align-items: center;
  justify-content: space-between;
}

.formGroup h1,
.formGroup h2,
.formGroup h3,
.formGroup h4 {
  margin-bottom: 10px;
  color: var(--light-gray);
  font-size: 24px;
  font-weight: 600;
}

.egitimolusturHeader {
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary-color);
  font-size: 36px;
  font-weight: 700;
  line-height: 35px;
}

.formGroup input[type="text"] {
  width: 410px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  height: 30px;
  border: 1px solid var(--light-gray);
  color: var(--white);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-radius: 4px;
  background-color: var(--black);
  outline: none;
  caret-color: var(--primary-color);
}

.formGroup input:-webkit-autofill,
.formGroup input:-webkit-autofill:hover,
.formGroup input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--white);
  -webkit-box-shadow: 0 0 0px 1000px var(--black) inset;
  font-family: "Montserrat", sans-serif;
  caret-color: var(--primary-color);
}

.dropZone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;
  width: 400px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropZone p {
  margin: 0;
  color: var(--light-gray);
}

.dropZone input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadedImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.adminUploadButton {
  background-color: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.adminUploadButton:hover {
  background-color: transparent;
}

.adminUploadButton:focus {
  outline: none;
}

@media (max-width: 1268px) {
  .formGroup input[type="text"] {
    width: 270px;
  }

  .dropZone {
    width: 260px;
    height: 200px;
  }
  .egitimolusturInner {
    width: 700px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .formTextInputs {
    height: 100px;
  }

  .formFileInputs {
    height: 350px;
  }

  .formGroup h1,
  .formGroup h2,
  .formGroup h3,
  .formGroup h4 {
    margin-bottom: 10px;
    color: var(--light-gray);
    font-size: 20px;
    font-weight: 500;
  }
}

@media (max-width: 968px) {
  .formGroup input[type="text"] {
    width: 273px;
  }
  .egitimolusturContainer {
    height: 1100px;
  }
  .egitimolusturInner {
    width: 305px;
  }
  .formTextInputs {
    flex-direction: column;
    height: 200px;
    margin-top: 50px;
  }

  .formFileInputs {
    margin-top: 30px;
    flex-direction: column;
    height: 600px;
  }
  .egitimolusturHeader {
    text-align: left;
    font-size: 30px;
  }
}
