@import url("../../../Constants/Colors.css");

.landingPageSSSContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPageSSSInner {
  width: 80%;
  height: auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landingPageSSSTitle {
  font-size: 48px;
  font-weight: 600;
  color: var(--white);
  text-align: center;
}

.landingPageSSSTitleColored {
  color: var(--primary-color);
}

.landingPageSSSContent {
  width: 100%;
  height: auto;
  margin-top: 70px;
}

.expandable-section {
  cursor: pointer;
  border: 1px solid var(--white);
  box-shadow: 0px 0px 30px -5px var(--primary-color);
  border-radius: 30px;
  overflow: hidden;
  color: var(--white);
  margin-bottom: 30px;
  width: 100%;
}

.summary-title {
  font-size: 20px;
  font-weight: 700;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
}

.summary-title-text {
  padding-right: 30px;
  line-height: 22px;
}

.content {
  font-size: 16px;
  max-height: 0;
  opacity: 0;
  transition: all 300ms ease-in-out;
  color: #ddd;
}

.expandable-section.open .content {
  max-height: 500px; /* Set a max-height larger than the content */
  opacity: 1;
  line-height: 26px;
  padding: 0px 25px 25px 25px;
}

.chevron-icon {
  transition: transform 300ms ease;
}

.chevron-icon.rotate {
  transform: rotate(90deg);
}

.landingPageSSSQuest {
  text-align: center;
  color: var(--white);
  margin-top: 60px;
  font-size: 18px;
}

.landingPageSSSButton {
  width: 200px;
  height: 50px;
  font-size: 18px;
  background-color: var(--primary-color);
  box-sizing: border-box;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--white);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  cursor: pointer;
  transition: 300ms all;
}

.landingPageSSSButton:hover {
  background-color: transparent;
}

.SSSWhatsappIcon {
  font-weight: 500;
  font-size: 20px;
  margin-right: 10px;
}

@media (max-width: 968px) {
  .summary-title {
    font-size: 16px;
  }
  .content {
    font-size: 14px;
  }
  .landingPageSSSInner {
    padding-bottom: 50px;
  }
  .landingPageSSSTitle {
    font-size: 40px;
  }
}

@media (max-width: 568px) {
  .landingPageSSSTitle {
    font-size: 32px;
    line-height: 36px;
  }
  .landingPageSSSQuest {
    text-align: center;
    color: var(--white);
    margin-top: 40px;
    font-size: 16px;
    text-align: center;
    line-height: 20px;
  }

  .landingPageSSSButton {
    width: 150px;
    height: 42px;
    font-size: 16px;
    margin-top: 25px;
  }

  .landingPageSSSButton:hover {
    background-color: transparent;
  }

  .SSSWhatsappIcon {
    font-size: 18px;
    margin-right: 5px;
  }
}

@media (max-width: 468px) {
  .summary-title {
    font-size: 14px;
  }
  .content {
    font-size: 12px;
  }
  .landingPageSSSInner {
    padding-bottom: 40px;
  }
}
