@import url("../../../Constants/Colors.css");

.notFoundContainer {
  width: 100%;
  height: 550px;
  padding-top: 100px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notFoundInner {
  width: auto;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.notFoundHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: auto;
  height: 200px;
}

.notFound404 {
  color: var(--primary-color);
  font-size: 140px;
  font-weight: 700;
}

.notFoundText {
  font-size: 30px;
  color: var(--white);
  font-weight: 500;
  text-align: center;
}

.notFoundCTA {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  color: var(--light-gray);
  cursor: pointer;
  transition: all 300ms;
}

.notFoundCTA:hover {
  color: var(--primary-color);
}

.notFoundCTA:hover > .notFoundCTAI {
  color: var(--primary-color);
}

.notFoundCTAI {
  margin-left: 10px;
  font-size: 22px;
  font-weight: 400;
  color: var(--light-gray);
  cursor: pointer;
  transition: all 300ms;
}

@media (max-width: 738px) {
  .notFoundContainer {
    height: 450px;
  }
  .notFoundInner {
    width: auto;
    height: 250px;
  }
  .notFoundHeader {
    width: auto;
    height: 150px;
  }

  .notFound404 {
    font-size: 100px;
    font-weight: 700;
  }

  .notFoundText {
    font-size: 24px;
  }

  .notFoundCTA {
    font-size: 20px;
    font-weight: 500;
  }
  .notFoundCTAI {
    font-size: 18px;
  }
}

@media (max-width: 478px) {
  .notFoundContainer {
    height: 470px;
  }
  .notFoundInner {
    width: auto;
    height: 270px;
  }
  .notFoundHeader {
    width: auto;
    height: 170px;
  }
}

@media (max-width: 338px) {
  .notFoundContainer {
    height: 470px;
  }
  .notFoundInner {
    width: auto;
    height: 270px;
  }
  .notFoundHeader {
    width: auto;
    height: 170px;
  }

  .notFound404 {
    font-size: 90px;
    font-weight: 700;
  }

  .notFoundText {
    font-size: 20px;
  }

  .notFoundCTA {
    font-size: 18px;
    font-weight: 500;
  }
  .notFoundCTAI {
    font-size: 16px;
  }
}
