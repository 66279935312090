@import url("../../../Constants/Colors.css");

.adminAsideContainer {
  position: fixed; /* Sayfa içeriğinden bağımsız olarak sabit kalacak */
  width: 270px;
  height: 100%; /* Sayfa yüksekliği kadar olacak */
  min-height: 100vh; /* Minimum sayfa yüksekliği kadar olacak */
  background-color: #161616;
  transition: width 0.3s ease;
  z-index: 100; /* İçeriğin üzerinde olacak şekilde yüksek z-indeksi */
}

.adminAsideContainer.closed {
  width: 60px;
}

.adminAsideInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.adminAsideHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  color: var(--white);
}

.adminAsideContainer.closed .adminAsideHeader {
  height: 36px;
}

.adminAsideHeaderInner {
  width: auto;
  display: flex;
  align-items: center;
}

.adminName {
  font-size: 18px;
  font-weight: bold;
}

.adminProfileImg {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  margin-right: 15px;
}

.toggleButton {
  background: none;
  border: none;
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
}

.adminMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.adminMenuItem {
  display: flex;
  align-items: center;
  padding: 20px;
  color: var(--white);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.adminMenuItem:hover {
  background-color: #1d1d1d;
}

.adminMenuItem span {
  margin-left: 15px;
  font-size: 16px;
}

.closed .adminMenuItem span {
  display: none;
}

.adminAsideIcon {
  color: var(--light-gray);
}
