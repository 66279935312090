@import url("../../../Constants/Colors.css");

.watchVideoContainer {
  width: 100%;
  padding-top: 140px;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: var(--black);
}

.watchVideoInner {
  width: 1350px;
  height: auto;
}

.watchVideoGridContainer {
  display: grid;
  grid-template-columns: 850px 450px; /* Soldaki kutunun genişliği 900px, sağdakinin 500px olarak ayarlandı */
  gap: 50px; /* İki grid öğesi arasındaki boşluk */
}

.watchVideoGridItem {
  border-radius: 5px;
}

.watchVideoGridItem h1 {
  font-size: 28px;
  line-height: 24px;
  color: var(--white);
  font-weight: 700;
  display: flex;
  align-items: center;
  height: 100px;
}

.watchVideoGridContainer .watchVideoGridItem:nth-child(1),
.watchVideoGridContainer .watchVideoGridItem:nth-child(2) {
  height: auto; /* Üstteki satırdaki kutuların yüksekliği 600px olarak ayarlandı */
}

.watchVideoGridContainer .watchVideoGridItem:nth-child(3),
.watchVideoGridContainer .watchVideoGridItem:nth-child(4) {
  height: auto; /* Alt satırdaki kutuların yüksekliği 1000px olarak ayarlandı */
}

.watchVideoGridItem iframe {
  width: 100%;

  height: 500px; /* Veya istediğiniz yükseklik değeri */
  border: none;
  border-radius: 5px;
}

.videoPlayerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.watchVideoLeaveCommentArea {
  background-color: var(--white);
  border-radius: 15px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  cursor: pointer;
}

.watchVideoLeaveCommentAreaInner {
  background-color: var(--white);
  border-radius: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 250px;
}

.watchVideoLeaveCommentUpArea {
  height: 70px;
}

.watchVideoLeaveCommentDownArea {
  width: 100%;
  display: flex;
}

.watchVideoLeaveCommentDownInner {
  display: flex;
  flex-direction: row; /* Ensure content stacks vertically */
  border: 2px solid var(--gray);
  border-radius: 30px;
  padding: 20px;
  width: 410px;
  height: 110px;
}

.watchVideoCommentInput {
  border: none;
  outline: none;
  font-size: 16px;
  padding-right: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: var(--black);
  resize: none; /* Prevent resizing by the user */
  background-color: var(--white);
  height: 100%;
  overflow-y: auto;
  width: 100%; /* Take full width of the container */
  caret-color: var(--primary-color);

  /* Hide the scrollbar */
  scrollbar-width: none; /* For Firefox */
}

.watchVideoCommentInput::placeholder {
  color: var(--gray);
}

.commentInput::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.watchVideoLeaveCommentHeader {
  font-size: 20px;
  color: var(--black);
  font-weight: 600;
  margin-top: 30px;
}

.watchVideoLeaveCommentDownInner span {
  display: flex;
  align-items: flex-end;

  font-weight: 500;
  cursor: pointer;
  color: var(--gray);
}

.watchVideoLeaveCommentDownInner span:hover {
  color: var(--black);
}

.watchVideoLeaveCommentInner {
  width: 500px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.watchVideoLeaveCommentContainer {
  display: flex;
  justify-content: center;
  height: auto;
}

.watchVideoContentArea {
  background-color: var(--white);
  border-radius: 20px;
  width: 450px;

}

.watchVideoContentInner {
  width: 400px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.categoryWatch{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
}
.categoryWatchInner{
  width: 400px;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  align-items: center;
  font-weight: 600;
  color: var(--black);
}

.categoryIcon{
  font-size: 16px;
  transition: transform 0.3s ease;
}

.watchVideoContentHeaderInner {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.watchVideoContentLeftIcon {
  font-size: 24px;
  margin-right: 15px;
  color: var(--black);
}
.watchVideoContentLeft{
  max-width: 350px;
}

.watchVideoContentLeft,
.watchVideoContentRight {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  color: var(--black);
}

.watchVideoContentRight {
  display: flex;
  justify-content: end;
  color: var(--gray);
}

.watchVideoContentRightIcon {
  font-size: 24px;
  margin-left: 15px;
  color: var(--gray);
}

.watchVideoContentHeaderIcon {
  transition: transform 0.3s ease;
  color: var(--black);
}

.watchVideoContentHeaderIcon.rotate {
  transform: rotate(90deg);
}

.watchVideoContentHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 90px;
}

.watchVideoCommentGrid {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.watchVideoCommentArea {
  background-color: var(--white);
  border-radius: 20px;
  width: 850px;
  max-height: 590px;
}

.watchVideoCommentItem {
  display: flex;
  width: 850px;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-bottom: 20px;
  padding-top: 20px;
  height: auto;
}

.watchVideoCommentItem:last-child {
  margin-bottom: 0; /* Remove bottom margin for the last comment item */
}

.watchVideoCommentPhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.watchVideoCommentContent {
  flex: 1;
}

.watchVideoCommentHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.watchVideoCommentName {
  font-weight: 600;
  color: var(--black);
  font-size: 14px;
}

.watchVideoCommentDate {
  font-size: 12px;
  color: var(--gray);
}

.watchVideoCommentText {
  font-size: 16px;
  color: var(--black);
  line-height: 17px;
  width: 90%;
}

.commentAltGroupIcon {
  width: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
}

.noCommentsMessage {
  text-align: center;
  font-size: 18px;
  color: var(--gray);
  padding-bottom: 50px;
  padding-top: 50px;
  font-weight: 600;
}

.watchVideoCommentHeaderInner {
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.watchVideoCommentListInner {
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.watchVideoCommentHeaderInner {
  width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.watchVideoContentList.expand {
  max-height: 410px; /* Adjust based on your content length */
  overflow-y: auto;
  box-shadow: 0px -1px 0 0 rgb(70, 70, 70);
}

.watchVideoContentList {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

/* Adding styles for scrollbar */
.watchVideoContentList.expand::-webkit-scrollbar {
  width: 10px;
  background-color: var(--white); /* Scrollbar background color */
}

.watchVideoContentList.expand::-webkit-scrollbar-thumb {
  background-color: var(--gray); /* Scrollbar thumb color */
  border-radius: 10px;
  border: none;
}

.watchVideoContentList.expand::-webkit-scrollbar-track {
  background-color: var(--white); /* Scrollbar track background color */
  border-radius: 10px;
}

.watchVideoContentList.expand::-webkit-scrollbar-corner {
  background-color: var(--white);
}

.watchVideoContentList.expand::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

@media (max-width: 1398px) {
  .watchVideoGridContainer {
    grid-template-columns: 700px 350px; /* Soldaki kutunun genişliği 900px, sağdakinin 500px olarak ayarlandı */
    gap: 50px; /* İki grid öğesi arasındaki boşluk */
  }
  .watchVideoContentArea {
    width: 350px;
  }

  .watchVideoInner {
    width: 1100px;
    height: auto;
  }
  .watchVideoContentInner {
    width: 300px;
  }
  .watchVideoContentHeaderInner {
    width: 300px;
  }

  .categoryWatchInner{
    width: 300px;
    font-size: 18px;
  }
  .watchVideoLeaveCommentInner {
    width: 350px;
  }
  .watchVideoLeaveCommentArea {
    width: 350px;
    height: 250px;
  }
  .watchVideoLeaveCommentAreaInner {
    width: 300px;
    height: 250px;
  }
  .watchVideoContentLeft{
    max-width: 250px;
    font-size: 15px;
  }
  .watchVideoLeaveCommentDownInner {
    display: flex;
    flex-direction: row; /* Ensure content stacks vertically */
    border: 2px solid var(--gray);
    border-radius: 30px;
    padding: 20px;
    width: 410px;
    height: 110px;
  }
  .watchVideoCommentHeaderInner {
    width: 650px;
  }

  .watchVideoCommentListInner {
    width: 650px;
  }

  .watchVideoCommentHeaderInner {
    width: 650px;
  }

  .watchVideoCommentArea {
    width: 700px;
  }

  .watchVideoCommentItem {
    width: 700px;
  }

  .watchVideoGridItem iframe {
    width: 700px;

    height: 370px; /* Veya istediğiniz yükseklik değeri */
    border: none;
    border-radius: 5px;
  }
  .watchVideoContentList.expand {
    max-height: 280px; /* Adjust based on your content length */
  }
}

@media (max-width: 1138px) {
  .watchVideoGridContainer {
    grid-template-columns: 800px; /* Tek sütuna düşürme */
    gap: 20px; /* İki grid öğesi arasındaki boşluk */
  }

  .watchVideoInner {
    width: 800px;
  }

  .watchVideoCommentGrid {
    justify-content: center;
    padding: 100px 0px 0px 0px;
  }

  .watchVideoContentArea {
    width: 100%;
    max-width: 100%;
  }

  .watchVideoGridItem iframe {
    width: 100%;
    height: 430px; /* Veya istediğiniz yükseklik değeri */
    border: none;
    border-radius: 5px;
  }
  .watchVideoCommentArea {
    width: 800px;
  }
  .watchVideoCommentHeaderInner {
    width: 700px;
  }
  .watchVideoCommentItem {
    width: 800px;
  }
  .watchVideoCommentListInner {
    width: 750px;
  }
  .watchVideoContentHeaderInner {
    width: 700px;
  }
  .categoryWatchInner{
    width: 700px;
    font-size: 20px;
  }
  .watchVideoContentInner {
    width: 700px;
  }
  .watchVideoKurs {
    margin-top: 0px;
  }

  .watchVideoLeaveCommentInner {
    width: 800px;
  }
  .watchVideoLeaveCommentArea {
    width: 800px;
    height: 300px;
  }
  .watchVideoLeaveCommentAreaInner {
    width: 730px;
    height: 280px;
  }
  .watchVideoLeaveCommentDownInner {
    width: 700px;
    height: 130px;
  }
  .watchVideoLeaveCommentUpArea {
    height: 80px;
  }
  .watchVideoContainer {
    padding-bottom: 0px;
    padding-top: 115px;
  }
  .watchVideoLeaveCommentContainer {
    padding-bottom: 100px;
    padding-top: 100px;
  }
  .watchVideoContentList.expand {
    max-height: 500px; /* Adjust based on your content length */
  }
}

@media (max-width: 838px) {
  .watchVideoGridContainer {
    grid-template-columns: 600px; /* Tek sütuna düşürme */
    gap: 20px; /* İki grid öğesi arasındaki boşluk */
  }

  .watchVideoInner {
    width: 600px;
  }

  .watchVideoCommentGrid {
    justify-content: center;
  }

  .watchVideoContentArea {
    width: 100%;
    max-width: 100%;
  }

  .watchVideoGridItem iframe {
    width: 100%;
    height: 350px; /* Veya istediğiniz yükseklik değeri */
    border: none;
    border-radius: 5px;
  }

  .watchVideoCommentArea {
    width: 600px;
  }
  .watchVideoCommentHeaderInner {
    width: 530px;
  }
  .watchVideoCommentItem {
    width: 600px;
  }
  .watchVideoCommentListInner {
    width: 530px;
  }
  .watchVideoContentHeaderInner {
    width: 500px;
  }
  .watchVideoContentInner {
    width: 500px;
  }
  
  .categoryWatchInner{
    width: 500px;
    font-size: 18px;
  }
  .watchVideoKurs {
    margin-top: 0px;
  }

  .watchVideoLeaveCommentInner {
    width: 600px;
  }
  .watchVideoLeaveCommentArea {
    width: 600px;
    height: 300px;
  }
  .watchVideoLeaveCommentAreaInner {
    width: 530px;
    height: 280px;
  }
  .watchVideoLeaveCommentDownInner {
    width: 700px;
    height: 130px;
  }
  .watchVideoLeaveCommentUpArea {
    height: 80px;
  }
  .watchVideoContainer {
    padding-bottom: 0px;
    padding-top: 115px;
  }
  .watchVideoContentLeft,
  .watchVideoContentRight {
    font-size: 16px;
  }
  .watchVideoContentLeftIcon,
  .watchVideoContentRightIcon {
    font-size: 20px;
  }
}

@media (max-width: 638px) {
  .watchVideoGridContainer {
    grid-template-columns: 450px; /* Tek sütuna düşürme */
    gap: 20px; /* İki grid öğesi arasındaki boşluk */
  }

  .watchVideoInner {
    width: 450px;
  }

  .watchVideoCommentGrid {
    justify-content: center;
  }
  .watchVideoContentHeader {
    height: 75px;
  }

  .watchVideoContentArea {
    width: 100%;
    max-width: 100%;
  }
  .watchVideoGridContainer .watchVideoGridItem:nth-child(1) {
    height: 400px;
  }
  .watchVideoGridContainer .watchVideoGridItem:nth-child(1) h1 {
    font-size: 20px;
    font-weight: 500;
    height: 60px;
    margin-top: 20px;
  }
  .watchVideoGridItem iframe {
    width: 100%;
    height: 250px; /* Veya istediğiniz yükseklik değeri */
    border: none;
    border-radius: 5px;
  }
  .watchVideoCommentArea {
    width: 450px;
  }
  .watchVideoCommentHeaderInner {
    width: 400px;
  }
  .watchVideoCommentItem {
    width: 450px;
  }
  .watchVideoCommentListInner {
    width: 400px;
  }
  .watchVideoContentHeaderInner {
    width: 400px;
  }
  .watchVideoContentInner {
    width: 400px;
  }
  
  .categoryWatchInner{
    width: 400px;
    font-size: 16px;
  }
  .watchVideoKurs {
    margin-top: 0px;
  }

  .watchVideoLeaveCommentInner {
    width: 450px;
  }
  .watchVideoLeaveCommentArea {
    width: 450px;
    height: 350px;
  }
  .watchVideoLeaveCommentAreaInner {
    width: 400px;
    height: 330px;
  }
  .watchVideoLeaveCommentDownInner {
    width: 700px;
    height: 180px;
  }
  .watchVideoLeaveCommentUpArea {
    height: 80px;
  }
  .watchVideoContainer {
    padding-bottom: 0px;
    padding-top: 130px;
  }
  .watchVideoContentLeft,
  .watchVideoContentRight {
    font-size: 14px;
  }
  .watchVideoContentLeftIcon,
  .watchVideoContentRightIcon {
    font-size: 16px;
  }
  .watchVideoCommentText {
    font-size: 14px;
  }
  .watchVideoLeaveCommentContainer {
    padding-bottom: 50px;
  }
}

@media (max-width: 538px) {
  .watchVideoContentRightIcon,
  .watchVideoContentLeftIcon {
    display: none;
  }
  .watchVideoContentHeader {
    height: 60px;
  }
}

@media (max-width: 468px) {
  .watchVideoGridContainer {
    grid-template-columns: 300px; /* Tek sütuna düşürme */
    gap: 20px; /* İki grid öğesi arasındaki boşluk */
  }

  .watchVideoInner {
    width: 300px;
  }

  .watchVideoCommentGrid {
    justify-content: center;
  }

  .watchVideoContentArea {
    width: 100%;
    max-width: 100%;
  }
  .watchVideoGridContainer .watchVideoGridItem:nth-child(1) {
    height: auto;
  }
  .watchVideoGridContainer .watchVideoGridItem:nth-child(1) h1 {
    font-size: 20px;
    font-weight: 500;
    height: 60px;
  }
  .watchVideoGridItem iframe {
    width: 100%;
    height: 200px; /* Veya istediğiniz yükseklik değeri */
    border: none;
    border-radius: 5px;
  }
  .watchVideoCommentArea {
    width: 300px;
  }
  .watchVideoCommentHeaderInner {
    width: 270px;
  }
  .watchVideoCommentItem {
    width: 300px;
  }
  .watchVideoCommentListInner {
    width: 270px;
  }
  .watchVideoContentHeaderInner {
    width: 270px;
  }
  
  .categoryWatchInner{
    width: 270px;
    font-size: 16px;
  }
  .watchVideoContentInner {
    width: 270px;
  }
  .watchVideoKurs {
    margin-top: 0px;
  }

  .watchVideoLeaveCommentInner {
    width: 300px;
  }
  .watchVideoLeaveCommentArea {
    width: 300px;
    height: 440px;
  }
  .watchVideoLeaveCommentAreaInner {
    width: 270px;
    height: 400px;
  }
  .watchVideoLeaveCommentDownInner {
    width: 700px;
    height: 270px;
  }
  .watchVideoLeaveCommentUpArea {
    height: 70px;
  }
  .watchVideoContainer {
    padding-bottom: 0px;
    padding-top: 130px;
  }

  .watchVideoContentRight {
    font-size: 12px;
  }

  .watchVideoContentLeft {
    font-size: 13px;
    max-width: 220px;
    
  }

  .watchVideoContentRightIcon,
  .watchVideoContentLeftIcon {
    display: none;
  }
  .watchVideoCommentText {
    font-size: 13px;
  }
  .watchVideoCommentName {
    font-size: 13px;
  }
  .watchVideoCommentDate {
    font-size: 11px;
  }
  .watchVideoLeaveCommentHeader {
    margin-top: 20px;
  }
}
