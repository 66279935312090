@import url("../../../Constants/Colors.css");

.landingPageStudentContainer * {
  font-family: "Helvatica", sans-serif;
}

.landingPageStudentContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
}

.landingPageStudentInner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 70px;
}

.landingPageStudentTitle {
  font-size: 50px;
  font-weight: 700;
  color: var(--white);
}

.landingPageStudentTitleColored {
  color: var(--primary-color);
}
.landingPageStudentArea {
  display: flex;
  width: 90%;

  justify-content: space-between;
  margin-top: 70px;
  flex-wrap: wrap; /* Add this to allow wrapping */
}

.landingPageStudentColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 900px;
  justify-content: space-between;
  flex: 1 1 22%; /* Adjust this to manage column width */
}

.landingPageStudentColumn .landingPageStudentPhoto:last-child {
  margin-bottom: 0px;
}

.landingPageStudentPhoto {
  max-width: 300px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 2px solid var(--white);
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px var(--primary-color);
}

@media (max-width: 1398px) {
  .landingPageStudentPhoto {
    max-width: 250px;
  }
}

@media (max-width: 1168px) {
  .landingPageStudentPhoto {
    max-width: 230px;
  }
}

@media (max-width: 1098px) {
  .landingPageStudentPhoto {
    max-width: 300px;
  }
  .landingPageStudentColumn {
    flex: 1 1 45%; /* Adjust width for two columns */
    margin-bottom: 20px;
  }
  .landingPageStudentArea {
    width: 80%;
  }
  .landingPageStudentTitle {
    font-size: 42px;
  }
}

@media (max-width: 798px) {
  .landingPageStudentPhoto {
    max-width: 250px;
  }
  .landingPageStudentArea {
    width: 80%;
  }
  .landingPageStudentTitle {
    font-size: 38px;
  }
}

@media (max-width: 668px) {
  .landingPageStudentPhoto {
    max-width: 200px;
    box-shadow: 0px 0px 8px 2px var(--primary-color);
  }
  .landingPageStudentArea {
    width: 80%;
  }

  .landingPageStudentTitle {
    font-size: 32px;
    text-align: center;
  }
}

@media (max-width: 538px) {
  .landingPageStudentColumn {
    flex: 1 1 100%; /* Stack columns in a single column layout */
    margin-bottom: 20px;
  }
  .landingPageStudentPhoto {
    max-width: 350px;
  }
  .landingPageStudentArea {
    width: 100%; /* Adjust container width */
  }
  .landingPageStudentTitle {
    font-size: 30px;
    text-align: center;
  }
}

@media (max-width: 398px) {
  .landingPageStudentColumn {
    flex: 1 1 100%; /* Stack columns in a single column layout */
    margin-bottom: 20px;
  }
  .landingPageStudentPhoto {
    max-width: 290px;
  }
  .landingPageStudentArea {
    width: 100%; /* Adjust container width */
  }
  .landingPageStudentTitle {
    font-size: 28px;
    text-align: center;
  }
}
