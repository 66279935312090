@import url(../../../Constants/Colors.css);

:root {
  --login-background-url: url("/public/assets/Backgrounds/Login_Background.webp");
}

.loginContent {
  width: 100%;
  min-height: 100vh;
  height: auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.29) 1%,
      rgba(70, 70, 70, 0.333) 100%
    ),
    var(--login-background-url) !important;
  background-size: cover; /* Arka planın tamamen kapsamasını sağlar */
  background-position: center; /* Arka planın merkezlenmesini sağlar */
  background-repeat: no-repeat; /* Arka planın tekrar etmesini engeller */
}

.loginBox {
  width: 900px;
  height: 600px;

  border-radius: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginLeft {
  width: 500px;
  height: 600px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
}

.loginRight {
  width: 370px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginLogo {
  object-fit: cover;
  height: 600px;
  box-shadow: 0px 0px 20px 0px var(--primary-color);
  border-radius: 20px;
}

.loginForm {
  width: 410px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginHeaderArea {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.loginHeader {
  font-size: 52px;
  font-weight: 700;
  color: var(--black);
}
.loginSubtitle {
  font-size: 18px;
  color: var(--gray);
}

.loginFormArea {
  width: 320px;
  height: 315px;
}

.loginUsernameArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95px;
}

.loginUsername {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.loginPassArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95px;
  justify-content: space-between;
}

.loginButtonArea {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;
}

.loginRegister {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.loginUsernameInput {
  max-width: 300px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 5px;
  outline: none;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: #eaeaea;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-weight: 500;
}

.loginPassInput {
  max-width: 300px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 5px;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: #eaeaea;
  font-family: "Montserrat", sans-serif;
  color: var(--black);
  font-weight: 500;
}

.loginPass {
  font-size: 16px;
  color: var(--black);
  font-weight: 500;
}

.loginForgotPass {
  font-size: 14px;
  color: var(--gray);
  transition: all 200ms;
  margin-bottom: 5px;
  cursor: pointer;
}

.loginForgotPass:hover {
  color: var(--primary-color);
}

.loginButton {
  width: 320px;
  height: 45px;
  border: none;
  border-radius: 5px;
  outline: none;
  margin-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  background-color: var(--primary-color);
  font-family: "Montserrat", sans-serif;
  color: var(--white);
  font-weight: 500;
  box-shadow: 1px 1px 10px 1px #ff00004c;
  cursor: pointer;
}

/* .loginButton:hover {
  border: 2px var(--primary-color) solid;
  background-color: var(--white);
  color: var(--black);
} */

.loginSub {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray);
  transition: all 200ms;
  cursor: pointer;
}

.loginSub:hover {
  color: var(--primary-color);
}

.loginRegisterText {
  font-weight: 500;
  font-size: 16px;
  color: var(--gray);
}

.loginQuest {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
}

.loginRightText {
  font-size: 20px;
  font-weight: 500;
  color: var(--black);
  margin-top: 30px;
}

.loginRightInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loginRightDown {
  width: 100%;
  height: 50%;
}

@keyframes fadeInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Animasyonu uygulayan CSS sınıfı */
.fade-in-left-500 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 500ms forwards;
}

.fade-in-left-1000 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 1000ms forwards;
}

.fade-in-left-1500 {
  opacity: 0; /* Başlangıçta öğeyi görünmez yapar */
  animation: fadeInFromLeft 1000ms ease-in-out 1750ms forwards;
}

.fade-in-right-1000 {
  opacity: 0;
  animation: fadeInFromRight 1150ms ease-in-out 1150ms forwards;
}
