@import url("../../../Constants/Colors.css");

.landingPageProgramContainer {
  width: 100%;
  height: auto;
}

.landingPageProgram {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 700px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 70px;
  padding-bottom: 70px;
}

.landingPageProgramHeader {
  font-size: 66px;
  color: var(--white);
  font-weight: 800;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingPageProgramItemSubtitle {
  font-size: 24px;
  color: var(--primary-color);
  font-weight: 600;
}

.landingPageProgramDescription {
  line-height: 24px;
  font-size: 20px;
  color: #ddd;
  font-weight: 500;
  margin-top: 30px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.landingPageProgramVideo {
  width: 840px;
  height: 473px;
  margin-top: 60px;
}

.firstWord {
  color: var(--white); /* White color for the first word */
}

.remainingWords {
  color: var(--primary-color); /* Red color for remaining words */
}

@media (max-width: 1138px) {
  .landingPageProgramHeader {
    font-size: 60px;
  }

  .landingPageProgramItemSubtitle {
    font-size: 22px;
  }

  .landingPageProgramDescription {
    line-height: 22px;
    font-size: 18px;
  }

  .landingPageProgramVideo {
    width: 672px;
    height: 378px;
    margin-top: 60px;
  }
  .landingPageProgram {
    min-height: 550px;
  }
}

@media (max-width: 968px) {
  .landingPageProgramHeader {
    font-size: 50px;
  }

  .landingPageProgramItemSubtitle {
    font-size: 20px;
  }

  .landingPageProgramDescription {
    line-height: 20px;
    font-size: 16px;
  }

  .landingPageProgramVideo {
    width: 560px;
    height: 315px;
    margin-top: 60px;
  }
  .landingPageProgram {
    min-height: 450px;
  }
}

@media (max-width: 638px) {
  .landingPageProgramHeader {
    font-size: 42px;
  }

  .landingPageProgramItemSubtitle {
    font-size: 18px;
  }

  .landingPageProgramDescription {
    line-height: 18px;
    font-size: 14px;
  }

  .landingPageProgramVideo {
    width: 448px;
    height: 252px;
    margin-top: 40px;
  }
  .landingPageProgram {
    min-height: 400px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 498px) {
  .landingPageProgramHeader {
    font-size: 36px;
  }

  .landingPageProgramItemSubtitle {
    font-size: 16px;
  }

  .landingPageProgramDescription {
    line-height: 18px;
    font-size: 14px;
  }

  .landingPageProgramVideo {
    width: 336px;
    height: 189px;
    margin-top: 40px;
  }
  .landingPageProgram {
    min-height: 350px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

@media (max-width: 398px) {
  .landingPageProgramHeader {
    font-size: 28px;
  }

  .landingPageProgramItemSubtitle {
    font-size: 14px;
  }

  .landingPageProgramDescription {
    line-height: 16px;
    font-size: 12px;
  }

  .landingPageProgramVideo {
    width: 300px;
    height: 165px;
    margin-top: 40px;
  }
  .landingPageProgram {
    min-height: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
