@import url(../Constants/Colors.css);

.footerPanelContainer {
  width: 100%;
  height: 300px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 -2px 2px -2px rgb(70, 70, 70);
}

.footerPanelInner {
  width: 1200px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footerPanelInfo {
  width: 1100px;
  margin-bottom: 70px;
}

.footerPanelLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footerPanelInfo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerPanelSocial {
  background-color: #aaa;
}

.footerPanelImg {
  width: 350px;
  cursor: pointer;
}

.footerPanelSubtitle {
  font-size: 16px;

  margin-top: 30px;
  color: var(--light-gray);
}

.footerPanelInfoTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
}

.footerPanelInfoInner {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footerPanelInfoList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap; /* Ensure the items wrap to the next line */
}

.footerPanelInfoListItem {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--light-gray);
  transition: all 300ms;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--white);
  height: 40px;
  border-radius: 30px;
}

.footerPanelInfoListLink {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  color: var(--light-gray);
  transition: all 300ms;
}

.footerPanelInfoListItem:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.footerPanelBerat {
  font-weight: 500;
  cursor: pointer;
  transition: color 200ms;
  color: var(--primary-color);
}

.footerPanelBerat:hover {
  color: var(--light-gray);
}

@media (max-width: 1338px) {
  .footerPanelImg {
    width: 300px;
  }
  .footerPanelSubtitle {
    font-size: 16px;
  }
  .footerPanelArea {
    width: 1000px;
  }
  .footerPanelInfoListItem {
    width: 230px;
    font-size: 14px;
  }
}

@media (max-width: 1138px) {
  .footerPanelImg {
    width: 250px;
  }
  .footerPanelSubtitle {
    font-size: 14px;
  }
  .footerPanelArea {
    width: 900px;
  }
  .footerPanelInfoListItem {
    width: 200px;
    height: 35px;
    font-size: 13px;
  }
  .footerPanelImg {
    width: 270px;
  }
}

@media (max-width: 938px) {
  .footerPanelContainer {
    height: 350px;
  }
  .footerPanelInner {
    height: 350px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .footerPanelInfo {
    height: 70px;
    align-items: flex-end;
    margin-bottom: 0px;
    margin-top: 50px;
  }
  .footerPanelInfoList {
    width: 570px;
  }
  .footerPanelInfoListItem {
    width: 40%; /* 2 items per row */
    margin-top: 20px;
  }
  .footerPanelLogo {
    order: 1;
    width: 570px; /* İlk öğeyi %100 genişlikte yapar */
    height: 200px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
  }

  .footerPanelSubtitle {
    margin-top: 0px;
    margin-left: 30px;
    width: 270px;
  }
  .footerPanelArea {
    width: 570px;
  }
}

@media (max-width: 600px) {
  .footerPanelContainer {
    height: 550px;
  }
  .footerPanelInner {
    height: 500px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .footerPanelLogo {
    width: 300px; /* İlk öğeyi %100 genişlikte yapar */
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .footerPanelSubtitle {
    margin-top: 20px;
    margin-left: 0px;
    width: 270px;
  }
  .footerPanelArea {
    height: auto;
    margin-top: 0px;
    width: 300px;
  }
  .footerPanelImg {
    width: 270px;
  }
  .footerPanelInfoList {
    justify-content: center;
    width: 300px;
  }
  .footerPanelInfoListItem {
    width: 80%; /* 2 items per row */
    margin-top: 20px;
  }
}
