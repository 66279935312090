@import url("../../../Constants/Colors.css");

.adminHomeContainer {
  min-height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
  height: auto;
  overflow: scroll;
}

.adminHomeInner {
  width: 1200px;
  height: 85vh;
}

.adminHomeHeader {
  font-size: 36px;
  font-weight: 600;
  color: var(--white);
  margin-bottom: 30px;
}

.adminHomeLeftRight {
  display: flex;
  justify-content: space-between;
}

.adminHomeLeftSide {
  width: 750px;
  height: 440px;
  background-color: #161616;
  border-radius: 30px;
  box-shadow: 5px 5px 10px 0px #0000004c;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 20px;
  align-items: center;
}

.adminHomeLeftHeaderArea {
  width: 630px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid #2f2f2f7d;
}

.adminHomeLeftHeader {
  font-size: 26px;
  font-weight: 600;
  color: var(--primary-color);
}

.adminHomeLeftInner {
  width: 670px;
  height: 380px;
  overflow-y: scroll;
}

.adminHomeLeftInner::-webkit-scrollbar-track {
  background-color: #161616;
}

.adminHomeIstatistikContainer {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 400px;
  height: 500px;
}

.adminHomeIstatistikBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #161616;
  border-radius: 20px;
  cursor: pointer;
  transition: all 200ms;
  box-shadow: 5px 5px 10px 0px #0000004c;
}

.adminHomeIstatistikBox:hover {
  background-color: #1d1d1d;
}

.adminHomeIstatistikInner {
  width: 140px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.adminHomeIstatistikIcon {
  font-size: 55px;
  color: var(--white);
}

.adminHomeIstatistik {
  display: flex;
  flex-direction: column;
}

.adminHomeIstatistikHeader {
  color: var(--light-gray);
  font-size: 16px;
  font-weight: 500;
}

.adminHomeIstatistikValue {
  color: var(--primary-color);
  font-size: 35px;
  font-weight: 700;
  margin-top: 10px;
}

.homeItemContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.homeItemContainer:hover {
  background-color: #1d1d1d;
}

.homeItemInner {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 20px;

  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.homeItemHeader {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
}

.homeItemButtons {
  width: 100px;
  display: flex;
  justify-content: flex-end;
}

.homeInnerIconContainer {
  position: relative;
}

.homeInnerIcon {
  color: var(--white);
  font-size: 20px !important;
  width: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  cursor: pointer;
}
.homeItemButton {
  width: 160px;
  height: 32px;
  font-size: 13px;
  border-radius: 30px;
  background-color: transparent;
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  margin-left: 30px;
  transition: all 300ms;
  cursor: pointer;
}

.homeItemButton:hover {
  background-color: var(--primary-color);
}

@media (max-width: 1338px) {
  .adminHomeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 650px;
  }
  .adminHomeInner {
    width: 1000px;
    height: auto;
  }
  .adminHomeLeftRight {
    width: 1000px;
    height: 500px;
  }
  .adminHomeLeftSide {
    width: 550px;
  }
  .adminHomeLeftInner {
    width: 550px;
  }

  .homeItemContainer {
    width: 530px;
  }
  .homeItemInner {
    width: 470px;
  }
  .adminHomeLeftHeaderArea {
    width: 490px;
  }
}

@media (max-width: 1138px) {
  .adminHomeHeader {
    font-size: 30px;
  }
  .adminHomeContainer {
    min-height: 600px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .adminHomeInner {
    width: 900px;
    height: auto;
  }
  .adminHomeLeftRight {
    width: 900px;
    height: 430px;
  }

  .adminHomeIstatistikIcon {
    font-size: 40px;
  }

  .adminHomeIstatistikHeader {
    font-size: 13px;
  }

  .adminHomeIstatistikValue {
    font-size: 30px;
  }
  .adminHomeIstatistikBox {
    height: 190px;
    width: 150px;
  }
  .adminHomeIstatistikInner {
    width: 100px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .adminHomeIstatistikContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    column-gap: 10px;
    width: 310px;
    height: 430px;
  }
  .adminHomeLeftSide {
    width: 550px;
    height: 370px;
  }
  .adminHomeRightSide {
    height: 430px;
  }
  .adminHomeLeftInner {
    height: 300px;
  }
}

@media (max-width: 998px) {
  .adminHomeHeader {
    font-size: 30px;
  }
  .adminHomeContainer {
    min-height: 600px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .adminHomeInner {
    width: 630px;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .adminHomeLeftRight {
    width: 630px;
    height: 650px;
    flex-direction: column-reverse;
  }

  .adminHomeIstatistikIcon {
    font-size: 40px;
  }

  .adminHomeIstatistikHeader {
    font-size: 13px;
  }

  .adminHomeIstatistikValue {
    font-size: 30px;
  }
  .adminHomeIstatistikBox {
    height: 190px;
    width: 150px;
  }
  .adminHomeIstatistikInner {
    width: 100px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .adminHomeIstatistikContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    column-gap: 10px;
    width: 630px;
    height: 190px;
  }

  .adminHomeLeftSide {
    width: 630px;
    height: 370px;
  }
  .adminHomeRightSide {
    height: 190px;
    width: 630px;
  }

  .homeItemContainer {
    width: 610px;
  }
  .homeItemInner {
    width: 590px;
  }
  .adminHomeLeftHeaderArea {
    width: 590px;
  }
  .adminHomeLeftInner {
    width: 630px;
  }
}

@media (max-width: 768px) {
  .adminHomeIstatistikContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 30px;
    width: 400px;
    height: 470px;
  }
  .adminHomeRightSide {
    height: 470px;
    width: 400px;
  }
  .adminHomeLeftRight {
    height: 950px;
    width: 400px;
  }
  .adminHomeInner {
    width: 400px;
  }

  .adminHomeIstatistikIcon {
    font-size: 46px;
  }

  .adminHomeIstatistikHeader {
    font-size: 14px;
  }

  .adminHomeIstatistikValue {
    font-size: 36px;
  }
  .adminHomeIstatistikBox {
    height: 220px;
    width: 180px;
  }
  .adminHomeIstatistikInner {
    width: 130px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .adminHomeLeftSide {
    width: 400px;
    height: 370px;
  }

  .homeItemContainer {
    width: 380px;
  }
  .homeItemInner {
    width: 330px;
  }
  .adminHomeLeftHeaderArea {
    width: 360px;
  }
  .adminHomeLeftInner {
    width: 400px;
  }
  .homeItemHeader {
    font-size: 16px;
  }
  .adminHomeLeftHeader {
    font-size: 22px;
  }
  .homeItemButton {
    width: 150px;
    font-size: 12px;
  }
}

@media (max-width: 488px) {
  .adminHomeIstatistikContainer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 320px;
    height: 430px;
  }
  .adminHomeRightSide {
    height: 430px;
    width: 300px;
  }
  .adminHomeLeftRight {
    height: 900px;
    width: 320px;
  }
  .adminHomeInner {
    width: 320px;
  }

  .adminHomeIstatistikIcon {
    font-size: 40px;
  }

  .adminHomeIstatistikHeader {
    font-size: 12px;
  }

  .adminHomeIstatistikValue {
    font-size: 30px;
  }
  .adminHomeIstatistikBox {
    height: 200px;
    width: 150px;
  }
  .adminHomeIstatistikInner {
    width: 100px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .adminHomeLeftSide {
    width: 320px;
    height: 370px;
  }

  .homeItemContainer {
    width: 280px;
  }
  .homeItemInner {
    width: 270px;
  }
  .adminHomeLeftHeaderArea {
    width: 260px;
  }
  .adminHomeLeftInner {
    width: 300px;
  }
  .homeItemHeader {
    font-size: 16px;
  }
  .adminHomeLeftHeader {
    font-size: 22px;
  }
  .homeItemButton {
    width: 150px;
    font-size: 12px;
  }
  .homeItemButton {
    display: none;
  }
}
