@import url("../Constants/Colors.css");

.copyrightContainer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--black);
}

.copyrightInner {
  width: 500px;
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--white);
}

.copyrightColored {
  cursor: pointer;
  transition: all 200ms;
}

.copyrightColored:hover {
  color: var(--primary-color);
}

@media (max-width: 1038px) {
  .copyrightInner {
    width: 500px;
    font-size: 16px;
  }
}

@media (max-width: 738px) {
  .copyrightInner {
    width: 400px;
    font-size: 14px;
  }
}

@media (max-width: 338px) {
  .copyrightInner {
    width: 300px;
    font-size: 12px;
  }
}
