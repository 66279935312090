@import url("../../../Constants/Colors.css");

.landingPageDontDoContainer {
  width: 100%;
  height: 300px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingPageDontDoInner {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.landingPageDontDoHeader {
  font-size: 30px;
  color: var(--primary-color);
  font-weight: 600;
  text-align: center;
  width: 90%;
}

.landingPageDontDoDescription {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
  margin-top: 30px;
  text-align: center;
  width: 90%;
  line-height: 20px;
}

@media (max-width: 968px) {
  .landingPageDontDoHeader {
    font-size: 26px;
  }

  .landingPageDontDoDescription {
    font-size: 16px;
    margin-top: 25px;
  }
}
