@import url("../../../Constants/Colors.css");

.navbarContainer {
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  position: fixed;
  background-color: var(--black);
  z-index: 1000;
  box-shadow: 0 2px 2px -2px rgb(70, 70, 70);
}

.navbarInner {
  width: 65%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbarLogoBanner {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarLogo {
  cursor: pointer;
}

.navLogo {
  width: 300px;
}

.navbarContent {
  width: 45%;
}

.navbarList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarListItem:last-child {
  border: 2px solid #b8b8b8;
  padding: 10px 30px;
  border-radius: 50px;
  transition: all 200ms linear;
}

.navbarListItem:last-child:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: all 200ms linear;
  cursor: pointer;
}

.navbarListItem:last-child .navbarLink.last {
  transition: all 200ms linear;
}

.navbarListItem:last-child:hover .navbarLink.last {
  color: var(--white);
}

.navbarLink:nth-child(1):hover,
.navbarLink:nth-child(2):hover,
.navbarLink:nth-child(3):hover {
  color: var(--primary-color);
}

.navbarLink {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  transition: color 200ms linear;
  cursor: pointer;
}

.navbarColored {
  color: var(--primary-color);
}

.icon {
  display: none;
  color: var(--white);
}

.navbarMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background-color: var(--black);
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 450ms ease-in-out;
}

/* Animation for opening the menu */
.navbarMobile.active {
  transform: translateY(0);
}

/* Animation for closing the menu */
.navbarMobile.exiting {
  transform: translateY(-100%);
}

.navbarMobileContainer {
  width: 100%;
  height: 300px;
  z-index: 999999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black);
}

.navbarMobileList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  width: 300px;
}

.navbarMobileLink {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  transition: color 200ms linear;
  cursor: pointer;
}

.mobileIcon {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  color: var(--white);
  transition: all 0ms ease;
}

.mobileIcon:hover {
  color: var(--primary-color);
}

.active {
  display: flex;
}

.navbarMobileLink:hover {
  color: var(--primary-color);
}

@media (max-width: 1638px) {
  .navbarContent {
    width: 45%;
  }

  .navbarInner {
    width: 75%;
  }
  .navbarLogoBanner {
    width: auto;
  }
}

@media (max-width: 1368px) {
  .navbarLink {
    font-size: 15px;
  }
  .navbarContent {
    width: 50%;
  }
  .navbarListItem:last-child {
    border: 2px solid #b8b8b8;
    padding: 7px 25px;
  }
}

@media (max-width: 1168px) {
  .icon {
    display: block;
    cursor: pointer;
  }
  .navbarList {
    display: none;
  }
  .navbarContent {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .navbarLogo {
    font-size: 20px;
  }
  .navbarInner {
    width: 75%;
  }
  .navbarLogoBanner {
    width: auto;
  }
  .navbarContent {
    width: auto;
  }
}

@media (max-width: 568px) {
  .navLogo {
    width: 150px;
  }
}
