@import url("../../../Constants/Colors.css");

.egitimDuzenleContainer {
  min-height: 100vh;
  background-color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 60px;
}

.egitimDuzenleInner {
  width: 1100px;
  min-height: 85vh;
  height: auto;
}

.egitimDuzenleHeader {
  font-size: 36px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 50px;
}

.egitimItemContainer {
  display: flex;
  align-items: center;
  height: 60px;
}

.egitimItemInner {
  display: flex;
  width: 1100px;
  align-items: center;
  justify-content: space-between;
}

.egitimItemHeader {
  font-size: 24px;
  font-weight: 600;
  width: 550px;
  color: var(--white);
}

.egitimItemButton {
  width: 200px;
  height: 35px;
  font-size: 14px;
  border-radius: 30px;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  transition: all 300ms;
  cursor: pointer;
}

.egitimItemButton:hover {
  background-color: transparent;
}

.egitimItemButtonSecond {
  width: 170px;
  height: 35px;
  font-size: 14px;
  border-radius: 30px;
  background-color: transparent;
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  margin-left: 20px;
  transition: all 300ms;
  cursor: pointer;
}

.egitimItemButtonSecond:hover {
  background-color: var(--primary-color);
}

.egitimItemButtonThird {
  width: 60px;
  height: 35px;
  font-size: 14px;
  border-radius: 30px;
  background-color: var(--primary-color);
  color: var(--white);
  font-weight: 500;
  border: 2px solid var(--primary-color);
  margin-left: 20px;
  transition: all 300ms;
  cursor: pointer;
}

.egitimItemButtonThird:hover {
  background-color: transparent;
}

.egitimItemButtons {
  display: flex;
  width: 490px;
  align-items: center;
  justify-content: flex-end;
}

.egitimListTitle {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
}

.egitimListHeaderContainer {
  height: 60px;
}

.egitimListHeaderInner {
  display: flex;
  justify-content: space-between;
  height: 30px;
  box-shadow: 0 2px 0px 0 var(--primary-color);
}

.egitimList:has(.popupMenu.open) {
  padding-bottom: 120px;
}
.egitimList {
  overflow-y: scroll;
  max-height: 60vh;
  height: auto;
}
.egitimInnerIcon {
  display: none;
}

/* Popup menu styles */
.popupMenu {
  position: absolute;
  top: 20px; /* Adjust based on your needs */
  width: 250px;
  right: 15px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: none;
}

.popupMenu.open {
  opacity: 1;
  transform: translateY(0);
  display: block;
}

.popupMenuItem {
  padding: 15px 20px;
  cursor: pointer;
}

.popupMenuItem:hover {
  background-color: #d7d7d7;
}

.egitimInnerIconContainer {
  position: relative;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

@media (max-width: 1238px) {
  .egitimDuzenleInner {
    width: 700px;
  }
  .egitimItemHeader {
    width: 290px;
    font-size: 18px;
    font-weight: 500;
  }
  .egitimItemButton {
    width: 160px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
  }

  .egitimItemButtonSecond {
    width: 140px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
  }

  .egitimItemButtonThird {
    width: 50px;
    height: 35px;
    font-size: 12px;
    font-weight: 500;
    margin-left: 20px;
  }
  .egitimItemInner {
    width: 700px;
  }
  .egitimItemButtons {
    width: 390px;
  }
}

@media (max-width: 838px) {
  .egitimInnerIcon {
    color: var(--white);
    font-size: 20px !important;
    width: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }
  .egitimItemInner {
    width: 470px;
  }
  .egitimItemButton,
  .egitimItemButtonSecond,
  .egitimItemButtonThird {
    display: none;
  }
  .egitimDuzenleInner {
    width: 500px;
  }
  .egitimItemButtons {
    width: 50px;
    display: flex;
    justify-content: flex-end;
  }
  .egitimInnerIconContainer {
    display: block;
  }
}

@media (max-width: 600px) {
  .egitimInnerIcon {
    color: var(--white);
    font-size: 20px;
    display: block;
  }
  .egitimItemButton,
  .egitimItemButtonSecond {
    display: none;
  }
  .egitimDuzenleInner {
    width: 300px;
  }
  .egitimItemButtons {
    width: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .egitimItemHeader {
    width: 240px;
    font-size: 16px;
    font-weight: 500;
  }
  .egitimDuzenleHeader {
    font-size: 30px;
    text-align: left;
  }
  .egitimItemInner {
    width: 280px;
  }
  .egitimInnerIconContainer {
    display: block;
  }
}
