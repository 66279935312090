@import url("../../../Constants/Colors.css");

.landingPagePropertyContainer * {
  font-family: "Helvatica", sans-serif;
}

.landingPagePropertyContainer {
  width: 100%;
  height: auto;
  background-color: var(--black);
  display: flex;
  justify-content: center;
}

.landingPagePropertyInner {
  width: 90%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 60px;
}

.landingPagePropertyTitle {
  font-size: 36px;
  color: var(--white);
  font-weight: 600;
  letter-spacing: 0.2px;
}

.landingPagePropertyTitleColored {
  color: var(--primary-color);
}

.landingPagePropertyItemsContainer {
  width: 95%;
  height: auto;
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.landingPagePropertyItemContainer {
  width: 450px;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: 0px 0px 12px 1px var(--primary-color);
  box-sizing: border-box;
  border-radius: 30px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.landingPagePropertyImage {
  max-width: 350px;
  max-height: 300px;
  object-fit: cover;
}

.landingPagePropertyImageArea {
  width: 400px;
  height: 300px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landingPagePropertyItemTitle {
  font-size: 24px;
  color: var(--primary-color);
  text-align: center;
  font-weight: 600;
}

.landingPagePropertyItemDescription {
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
}

.landingPagePropertyItemText {
  height: 150px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  align-items: center;
}

@media (max-width: 1638px) {
  .landingPagePropertyInner {
    width: 100%;
  }
}
@media (max-width: 1498px) {
  .landingPagePropertyItemContainer {
    width: 400px;
    height: 550px;
  }
  .landingPagePropertyImage {
    max-width: 300px;
    max-height: 250px;
  }

  .landingPagePropertyImageArea {
    width: 350px;
    height: 250px;
  }

  .landingPagePropertyItemTitle {
    width: 250px;
  }

  .landingPagePropertyItemText {
    height: 170px;
    width: 85%;
  }
}

@media (max-width: 1338px) {
  .landingPagePropertyItemContainer {
    width: 350px;
    height: 500px;
  }

  .landingPagePropertyItemTitle {
    font-size: 20px;
  }
  .landingPagePropertyItemDescription {
    font-size: 16px;
  }

  .landingPagePropertyImage {
    max-width: 300px;
    max-height: 200px;
  }

  .landingPagePropertyImageArea {
    width: 300px;
    height: 200px;
    padding-top: 10px;
  }

  .landingPagePropertyItemTitle {
    width: 250px;
  }

  .landingPagePropertyItemText {
    height: 150px;
    width: 85%;
  }
}

@media (max-width: 1168px) {
  .landingPagePropertyTitle {
    font-size: 30px;
  }
  .landingPagePropertyItemContainer {
    width: 300px;
    height: 400px;
  }

  .landingPagePropertyItemTitle {
    font-size: 18px;
  }
  .landingPagePropertyItemDescription {
    font-size: 14px;
  }

  .landingPagePropertyImage {
    max-width: 250px;
    max-height: 150px;
  }

  .landingPagePropertyImageArea {
    width: 250px;
    height: 150px;
    padding-top: 10px;
  }

  .landingPagePropertyItemTitle {
    width: 200px;
  }

  .landingPagePropertyItemText {
    height: 150px;
    width: 85%;
    padding-bottom: 10px;
  }
}

@media (max-width: 998px) {
  .landingPagePropertyItemContainer {
    width: 250px;
    height: 350px;
  }

  .landingPagePropertyItemTitle {
    font-size: 16px;
  }
  .landingPagePropertyItemDescription {
    font-size: 12px;
    line-height: 18px;
  }

  .landingPagePropertyImage {
    max-width: 200px;
    max-height: 150px;
  }

  .landingPagePropertyImageArea {
    width: 200px;
    height: 150px;
    padding-top: 10px;
  }

  .landingPagePropertyItemTitle {
    width: 200px;
  }

  .landingPagePropertyItemText {
    height: 130px;
    width: 85%;
    padding-bottom: 10px;
  }
}

@media (max-width: 838px) {
  .landingPagePropertyItemContainer {
    width: 200px;
    height: 320px;
  }

  .landingPagePropertyItemTitle {
    font-size: 16px;
  }
  .landingPagePropertyTitle {
    font-size: 24px;
  }
  .landingPagePropertyItemDescription {
    font-size: 12px;
    line-height: 18px;
  }

  .landingPagePropertyImage {
    max-width: 150px;
    max-height: 100px;
  }

  .landingPagePropertyImageArea {
    width: 150px;
    height: 100px;
    padding-top: 10px;
  }

  .landingPagePropertyItemTitle {
    width: 200px;
  }

  .landingPagePropertyItemText {
    height: 130px;
    width: 85%;
    padding-bottom: 10px;
  }
}

@media (max-width: 668px) {
  .landingPagePropertyItemsContainer {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .landingPagePropertyItemContainer {
    width: 500px;
    height: 400px;
    margin-top: 30px;
  }

  .landingPagePropertyItemTitle {
    font-size: 22px;
  }
  .landingPagePropertyTitle {
    font-size: 24px;

    text-align: center;
  }
  .landingPagePropertyItemDescription {
    font-size: 14px;
    line-height: 20px;
  }

  .landingPagePropertyImage {
    max-width: 450px;
    max-height: 200px;
  }

  .landingPagePropertyImageArea {
    width: 450px;
    height: 200px;
    padding-top: 10px;
  }

  .landingPagePropertyItemTitle {
    width: auto;
  }

  .landingPagePropertyItemText {
    height: 110px;
    width: 85%;
    padding-bottom: 10px;
  }
}

@media (max-width: 568px) {
  .landingPagePropertyItemsContainer {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .landingPagePropertyItemContainer {
    width: 350px;
    height: 430px;
    margin-top: 30px;
  }

  .landingPagePropertyItemTitle {
    font-size: 20px;
  }
  .landingPagePropertyTitle {
    font-size: 20px;
    width: 90%;
    text-align: center;
  }
  .landingPagePropertyItemDescription {
    font-size: 14px;
    line-height: 20px;
  }

  .landingPagePropertyImage {
    max-width: 250px;
    max-height: 200px;
  }

  .landingPagePropertyImageArea {
    width: 250px;
    height: 200px;
  }

  .landingPagePropertyItemTitle {
    width: 200px;
  }

  .landingPagePropertyItemText {
    height: 150px;
    width: 85%;
    padding-bottom: 10px;
  }
}

@media (max-width: 398px) {
  .landingPagePropertyItemsContainer {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .landingPagePropertyItemContainer {
    width: 285px;
    height: 400px;
    margin-top: 30px;
  }

  .landingPagePropertyItemTitle {
    font-size: 20px;
  }
  .landingPagePropertyTitle {
    font-size: 20px;

    text-align: center;
  }
  .landingPagePropertyItemDescription {
    font-size: 14px;
    line-height: 20px;
  }

  .landingPagePropertyImage {
    max-width: 240px;
    max-height: 150px;
  }

  .landingPagePropertyImageArea {
    width: 240px;
    height: 150px;
  }

  .landingPagePropertyItemTitle {
    width: 200px;
  }

  .landingPagePropertyItemText {
    height: 150px;
    width: 85%;
    padding-bottom: 10px;
  }
}
