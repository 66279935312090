@import url("../../../Constants/Colors.css");

.videoListTitle {
  font-size: 18px;
  color: var(--white);
  font-weight: 500;
}
.videoListTitle:nth-child(1) {
  width: 600px;
}

.videoListTitle:nth-child(2) {
  text-align: center;
  width: 100px;
}

.videoListTitle:nth-child(3) {
  text-align: right;
  width: 100px;
}

.videoItemHeader {
  font-size: 24px;
  font-weight: 600;
  width: 600px;
  color: var(--white);
}

.videoItemDuration {
  font-size: 20px;
  font-weight: 500;
  width: 100px;
  color: var(--white);
  text-align: center;
}

.videoItemContainer:hover {
  background-color: #1f1f1f;
  cursor: pointer;
}

.videoDuzenleHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.headerButton {
  width: 150px;
  height: 40px;
  background-color: var(--primary-color);
  color: var(--white);
  border-radius: 5px;
  border: 2px solid var(--primary-color);
  transition: 300ms all;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.headerButton:hover {
  background-color: transparent;
}

.videoDuzenleHeader {
  margin-bottom: 0px;
}

.uploadVideoInner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 800px;
}

.uploadVideoInput {
  width: 700px;
  height: 80px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  background-color: #161616;
  color: var(--white);
  outline: none;
  border: none;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  caret-color: var(--primary-color);
}

.uploadVideoInput::placeholder {
  color: var(--light-gray);
}

.uploadVideoModalContent {
  display: flex;
}

.videoDuzenleModalContent {
  border-radius: 5px;
  border-radius: 30px;
  width: 600px;
  height: 60px;

  display: flex;
  flex-direction: column;

  display: flex;
  align-items: center;
  justify-content: center;
}

.videoDuzenleModal {
  position: fixed;
  top: 0;
  padding-left: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
}

.uploadVideoButton {
  height: 100%;
  padding-right: 15px;
  padding-left: 15px;
  font-weight: 500;

  width: 100px;
  cursor: pointer;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #161616;
  color: var(--white);
}

.uploadVideoButton:hover {
  color: var(--primary-color);
}

.videoDuzenleNoVideosMessage {
  padding: 20px;
  text-align: center;
  font-size: 20px;
  color: var(--white);
  font-weight: 600;
}

.videoModalSectionLeft {
  width: 100%;
}

.videoDuzenleVideoContainer {
  width: 700px;
  height: 375px;
}
@media (max-width: 1238px) {
  .videoItemHeader {
    width: 500px;
    font-size: 18px;
  }
  .videoItemDuration {
    font-size: 16px;
  }
  .egitimItemButtonThird {
    height: 30px;
  }

  .egitimItemButtonThird:hover {
    background-color: transparent;
  }
  .videoListTitle:nth-child(1) {
    width: 500px;
  }

  .videoListTitle:nth-child(2) {
    text-align: center;
    width: 100px;
  }

  .videoListTitle:nth-child(3) {
    text-align: right;
    width: 100px;
  }
  .uploadVideoInner {
    width: 700px;
  }
  .uploadVideoInput {
    height: 70px;
    width: 620px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 10px;
  }
  .uploadVideoButton {
    width: 80px;
  }
}

@media (max-width: 1038px) {
  .videoDuzenleVideoContainer {
    width: 100%;
    height: 375px;
  }
}

@media (max-width: 838px) {
  .videoItemHeader {
    width: 400px;
    font-size: 16px;
  }
  .videoListTitle:nth-child(1) {
    width: 400px;
  }

  .videoListTitle:nth-child(2) {
    display: none;
  }

  .videoItemDuration {
    display: none;
  }

  .videoListTitle:nth-child(3) {
    text-align: right;
    width: 100px;
  }
  .videoDuzenleHeader {
    font-size: 26px;
  }
  .headerButton {
    width: 120px;
    font-size: 14px;
    height: 35px;
  }
  .videoDuzenleNoVideosMessage {
    font-size: 18px;
  }
  .uploadVideoInner {
    width: 500px;
  }
  .uploadVideoInput {
    height: 65px;
    width: 420px;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 10px;
  }
  .uploadVideoButton {
    width: 80px;
  }
  .videoDuzenleVideoContainer {
    width: 100%;
    height: 275px;
  }
}

@media (max-width: 630px) {
  .videoDuzenleVideoContainer {
    width: 100%;
    height: 175px;
  }
}

@media (max-width: 600px) {
  .videoItemHeader {
    width: 270px;
    font-size: 16px;
    font-weight: 500;
  }
  .videoListTitle:nth-child(1) {
    width: 300px;
    font-size: 14px;
  }

  .videoListTitle:nth-child(2) {
    display: none;
  }

  .videoItemDuration {
    display: none;
  }

  .videoListTitle:nth-child(3) {
    text-align: right;
    width: 100px;
    font-size: 14px;
  }
  .videoDuzenleHeader {
    font-size: 20px;
  }
  .headerButton {
    width: 85px;
    font-size: 12px;
    height: 32px;
  }
  .videoDuzenleNoVideosMessage {
    font-size: 16px;
  }
  .uploadVideoInner {
    width: 300px;
  }
  .uploadVideoInput {
    padding-left: 20px;
    padding-right: 5px;
    height: 50px;
    width: 240px;
    font-size: 14px;
    font-weight: 400;
  }
  .uploadVideoButton {
    width: 60px;
    padding: 0 10px 0 10px;
    font-size: 13px;
    font-weight: 400;
  }
  .videoDuzenleVideoContainer {
    width: 100%;
    height: 175px;
  }
}
